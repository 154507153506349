import React, { useState, useEffect, useCallback } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { supabase } from "./supabaseClient";
import LoginComponent from "./components/LoginComponent";
import HomePage from "./components/HomePage";
import LeaderboardPage from "./components/LeaderboardPage";
import ProfilePage from "./components/ProfilePage";
import ScoresPage from "./components/ScoresPage";
import ScrollToTop from "./components/ScrollToTop";
import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css"; //default toastify styles
import "./toastify.css"; // Add this line to import your custom styles
import Layout from "./Layout";
import AccountSettingsPage from "./components/AccountSettingsPage";
import SignupModal from "./components/SignupModal";
import MaintenancePage from "./components/MaintenancePage";
import PasswordResetPage from "./components/PasswordResetPage";
import UpdatePasswordPage from "./components/UpdatePasswordPage";
import { getGamesForWeek } from "./gameData";
import { getCurrentWeek } from "./components/weekDates";

const REFRESH_THRESHOLD = 20 * 60 * 1000; // 20 minutes in milliseconds

function App() {
  const [session, setSession] = useState(null);
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(true);
  const [isScrollable, setIsScrollable] = useState(true);
  const [hideBottomNav, setHideBottomNav] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
  const [isMaintenanceMode] = useState(false);
  const [hasSubmittedAllPicks, setHasSubmittedAllPicks] = useState(true);

  const fetchUserData = async (userId) => {
    try {
      const { data, error } = await supabase
        .from("profiles")
        .select("username")
        .eq("id", userId)
        .single();

      if (error) throw error;
      setUsername(data.username);

      // Fetch avatar URL
      const { data: avatarData, error: avatarError } = await supabase
        .from("user_avatars")
        .select("avatar_url")
        .eq("user_id", userId)
        .single();

      if (avatarError && avatarError.code !== "PGRST116") throw avatarError;
      setAvatarUrl(avatarData?.avatar_url || null);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const updateAvatarUrl = async (newAvatarUrl) => {
    setAvatarUrl(newAvatarUrl);
    // Update the avatar URL in the database
    try {
      const { error } = await supabase
        .from("user_avatars")
        .upsert(
          { user_id: session.user.id, avatar_url: newAvatarUrl },
          { onConflict: "user_id" }
        );

      if (error) throw error;
    } catch (error) {
      console.error("Error updating avatar URL:", error);
    }
  };

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const { data: sessionData, error } = await supabase.auth.getSession();
        if (error) throw error;
        setSession(sessionData.session);
        if (sessionData.session && sessionData.session.user) {
          fetchUserData(sessionData.session.user.id);
        }
      } catch (error) {
        console.error("Error fetching session:", error.message);
        setSession(null);
      } finally {
        setLoading(false);
      }
    };

    fetchSession();

    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (_event, sessionData) => {
        setSession(sessionData);
        if (sessionData && sessionData.user) {
          fetchUserData(sessionData.user.id);
        } else {
          setUsername("");
          setAvatarUrl(null);
        }
      }
    );

    return () => {
      if (authListener && authListener.subscription) {
        authListener.subscription.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    const initializeTimes = () => {
      const startTime =
        localStorage.getItem("startTime") || Date.now().toString();
      localStorage.setItem("startTime", startTime);
      localStorage.setItem("lastVisit", Date.now().toString());
    };

    initializeTimes();

    const updateLastVisit = () => {
      localStorage.setItem("lastVisit", Date.now().toString());
    };

    const checkRefresh = () => {
      const currentTime = Date.now();
      const startTime = parseInt(localStorage.getItem("startTime"), 10);
      const elapsedTime = currentTime - startTime;
      if (elapsedTime >= REFRESH_THRESHOLD) {
        refreshData();
        localStorage.setItem("startTime", currentTime.toString());
      }
      updateLastVisit();
    };

    checkRefresh();

    const interval = setInterval(checkRefresh, 1000);

    return () => clearInterval(interval);
  }, []);

  const refreshData = () => {
    // Your data refresh logic here
    console.log("Data refreshed!");
    // Refresh the page to ensure all data is up-to-date
    window.location.reload();
  };

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) console.log("Error logging out:", error.message);
    setUsername("");
  };

  useEffect(() => {
    // Add or remove the 'no-scroll' class based on isScrollable state
    if (isScrollable) {
      document.body.classList.remove("no-scroll");
    } else {
      document.body.classList.add("no-scroll");
    }
  }, [isScrollable]);

  const closeSignupModal = () => setIsSignupModalOpen(false);

  const handleSignup = (newUsername) => {
    console.log("Setting new username:", newUsername); // Add this log
    setUsername(newUsername);
    closeSignupModal();
  };

  // Add this function to check if all picks are submitted
  const checkAllPicksSubmitted = useCallback(async () => {
    if (!session || !session.user) return;

    try {
      const { data: userPicks, error } = await supabase
        .from("user_picks")
        .select("picks")
        .eq("user_id", session.user.id)
        .eq("week", getCurrentWeek())
        .single();

      if (error) {
        if (error.code === "PGRST116") {
          // No picks found for this week
          setHasSubmittedAllPicks(false);
          return;
        }
        throw error;
      }

      const picks = userPicks?.picks || {};
      const games = getGamesForWeek(getCurrentWeek());
      const allPicksSubmitted = games.every(
        (game, index) => picks[index] && picks[index] !== "Not Submitted"
      );

      setHasSubmittedAllPicks(allPicksSubmitted);
    } catch (error) {
      console.error("Error checking picks submission:", error);
      setHasSubmittedAllPicks(false);
    }
  }, [session]);

  // Add this effect to check picks submission status when session changes
  useEffect(() => {
    if (session) {
      checkAllPicksSubmitted();
    }
  }, [session, checkAllPicksSubmitted]);

  if (loading) {
    return <div></div>; // Or a more sophisticated loading component
  }

  if (isMaintenanceMode) {
    return <MaintenancePage />;
  }

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/password-reset" element={<PasswordResetPage />} />
        <Route path="/reset-password" element={<UpdatePasswordPage />} />
        {!session ? (
          <Route
            path="/"
            element={<LoginComponent setUsername={setUsername} />}
          />
        ) : (
          <Route
            path="/*"
            element={
              <Layout
                username={username}
                onLogout={handleLogout}
                hideBottomNav={hideBottomNav}
                setUserName={setUsername}
                avatarUrl={avatarUrl}
                updateAvatarUrl={updateAvatarUrl}
                hasSubmittedAllPicks={hasSubmittedAllPicks}
              >
                <Routes>
                  <Route
                    path="/"
                    element={<Navigate to="/leaderboard" replace />}
                  />
                  <Route path="/scores" element={<ScoresPage />} />
                  <Route
                    path="/home"
                    element={
                      <HomePage
                        username={username}
                        setIsScrollable={setIsScrollable}
                        setHideBottomNav={setHideBottomNav}
                        setHasSubmittedAllPicks={setHasSubmittedAllPicks}
                      />
                    }
                  />
                  <Route path="/leaderboard" element={<LeaderboardPage />} />
                  <Route
                    path="/profile"
                    element={
                      <ProfilePage
                        userId={session.user.id}
                        userName={username}
                      />
                    }
                  />
                  <Route
                    path="/account-settings"
                    element={
                      <AccountSettingsPage
                        userId={session.user.id}
                        userName={username}
                        setUserName={setUsername}
                        updateAvatarUrl={updateAvatarUrl}
                      />
                    }
                  />
                  <Route
                    path="*"
                    element={<Navigate to="/leaderboard" replace />}
                  />
                </Routes>
              </Layout>
            }
          />
        )}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <SignupModal
        isOpen={isSignupModalOpen}
        onClose={closeSignupModal}
        onSignup={handleSignup}
      />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        draggable
        theme="dark"
        limit={3}
      />
    </Router>
  );
}

export default App;
