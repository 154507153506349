export const weeklyGames = {
  1: [
    {
      home: {
        abbreviation: "KC",
        name: "Kansas City Chiefs",
        shortName: "Chiefs",
        spread: -3.0,
      },
      away: {
        abbreviation: "BAL",
        name: "Baltimore Ravens",
        shortName: "Ravens",
        spread: 3.0,
      },
      winner: "NULL",
      startTime: "2024-09-05T20:20:00-04:00", // Thursday 8:20 PM ET
    },
    {
      home: {
        abbreviation: "PHI",
        name: "Philadelphia Eagles",
        shortName: "Eagles",
        spread: -2.0,
      },
      away: {
        abbreviation: "GB",
        name: "Green Bay Packers",
        shortName: "Packers",
        spread: 2.0,
      },
      winner: "NULL",
      startTime: "2024-09-06T20:15:00-04:00", // Friday 8:15 PM ET
    },
    {
      home: {
        abbreviation: "ATL",
        name: "Atlanta Falcons",
        shortName: "Falcons",
        spread: -2.5,
      },
      away: {
        abbreviation: "PIT",
        name: "Pittsburgh Steelers",
        shortName: "Steelers",
        spread: 2.5,
      },
      winner: "NULL",
      startTime: "2024-09-08T13:00:00-04:00", // Sunday 1:00 PM ET
    },
    {
      home: {
        abbreviation: "BUF",
        name: "Buffalo Bills",
        shortName: "Bills",
        spread: -6.5,
      },
      away: {
        abbreviation: "ARI",
        name: "Arizona Cardinals",
        shortName: "Cardinals",
        spread: 6.5,
      },
      winner: "NULL",
      startTime: "2024-09-08T13:00:00-04:00", // Sunday 1:00 PM ET
    },
    {
      home: {
        abbreviation: "CHI",
        name: "Chicago Bears",
        shortName: "Bears",
        spread: -4.5,
      },
      away: {
        abbreviation: "TEN",
        name: "Tennessee Titans",
        shortName: "Titans",
        spread: 4.5,
      },
      winner: "NULL",
      startTime: "2024-09-08T13:00:00-04:00", // Sunday 1:00 PM ET
    },
    {
      home: {
        abbreviation: "CIN",
        name: "Cincinnati Bengals",
        shortName: "Bengals",
        spread: -9.0,
      },
      away: {
        abbreviation: "NE",
        name: "New England Patriots",
        shortName: "Patriots",
        spread: 9.0,
      },
      winner: "NULL",
      startTime: "2024-09-08T13:00:00-04:00", // Sunday 1:00 PM ET
    },
    {
      away: {
        abbreviation: "HOU",
        name: "Houston Texans",
        shortName: "Texans",
        spread: -2.0,
      },
      home: {
        abbreviation: "IND",
        name: "Indianapolis Colts",
        shortName: "Colts",
        spread: 2.0,
      },
      winner: "NULL",
      startTime: "2024-09-08T13:00:00-04:00", // Sunday 1:00 PM ET
    },
    {
      home: {
        abbreviation: "MIA",
        name: "Miami Dolphins",
        shortName: "Dolphins",
        spread: -3.5,
      },
      away: {
        abbreviation: "JAX",
        name: "Jacksonville Jaguars",
        shortName: "Jaguars",
        spread: 3.5,
      },
      winner: "NULL",
      startTime: "2024-09-08T13:00:00-04:00", // Sunday 1:00 PM ET
    },
    {
      home: {
        abbreviation: "NO",
        name: "New Orleans Saints",
        shortName: "Saints",
        spread: -4.5,
      },
      away: {
        abbreviation: "CAR",
        name: "Carolina Panthers",
        shortName: "Panthers",
        spread: 4.5,
      },
      winner: "NULL",
      startTime: "2024-09-08T13:00:00-04:00", // Sunday 1:00 PM ET
    },
    {
      home: {
        abbreviation: "NYG",
        name: "New York Giants",
        shortName: "Giants",
        spread: "E",
      },
      away: {
        abbreviation: "MIN",
        name: "Minnesota Vikings",
        shortName: "Vikings",
        spread: "E",
      },
      winner: "NULL",
      startTime: "2024-09-08T13:00:00-04:00", // Sunday 1:00 PM ET
    },
    {
      home: {
        abbreviation: "LAC",
        name: "Los Angeles Chargers",
        shortName: "Chargers",
        spread: -3.0,
      },
      away: {
        abbreviation: "LV",
        name: "Las Vegas Raiders",
        shortName: "Raiders",
        spread: 3.0,
      },
      winner: "NULL",
      startTime: "2024-09-08T16:05:00-04:00", // Sunday 4:05 PM ET
    },
    {
      home: {
        abbreviation: "SEA",
        name: "Seattle Seahawks",
        shortName: "Seahawks",
        spread: -5.0,
      },
      away: {
        abbreviation: "DEN",
        name: "Denver Broncos",
        shortName: "Broncos",
        spread: 5.0,
      },
      winner: "NULL",
      startTime: "2024-09-08T16:25:00-04:00", // Sunday 4:25 PM ET
    },
    {
      home: {
        abbreviation: "CLE",
        name: "Cleveland Browns",
        shortName: "Browns",
        spread: -2.0,
      },
      away: {
        abbreviation: "DAL",
        name: "Dallas Cowboys",
        shortName: "Cowboys",
        spread: 2.0,
      },
      winner: "NULL",
      startTime: "2024-09-08T16:25:00-04:00", // Sunday 4:25 PM ET
    },
    {
      home: {
        abbreviation: "TB",
        name: "Tampa Bay Buccaneers",
        shortName: "Buccaneers",
        spread: -3.5,
      },
      away: {
        abbreviation: "WSH",
        name: "Washington Commanders",
        shortName: "Commanders",
        spread: 3.5,
      },
      winner: "NULL",
      startTime: "2024-09-08T16:25:00-04:00", // Sunday 4:25 PM ET
    },
    {
      home: {
        abbreviation: "DET",
        name: "Detroit Lions",
        shortName: "Lions",
        spread: -3.5,
      },
      away: {
        abbreviation: "LAR",
        name: "Los Angeles Rams",
        shortName: "Rams",
        spread: 3.5,
      },
      winner: "NULL",
      startTime: "2024-09-08T20:20:00-04:00", // Sunday 8:20 PM ET
    },
    {
      home: {
        abbreviation: "SF",
        name: "San Francisco 49ers",
        shortName: "49ers",
        spread: -5.0,
      },
      away: {
        abbreviation: "NYJ",
        name: "New York Jets",
        shortName: "Jets",
        spread: 5.0,
      },
      winner: "NULL",
      startTime: "2024-09-09T20:15:00-04:00", // Monday 8:15 PM ET
    },
  ],
  2: [
    {
      home: {
        abbreviation: "MIA",
        name: "Miami Dolphins",
        shortName: "Dolphins",
        spread: -2,
      },
      away: {
        abbreviation: "BUF",
        name: "Buffalo Bills",
        shortName: "Bills",
        spread: 2,
      },
      winner: "NULL",
      startTime: "2024-09-12T20:15:00-04:00",
    },
    {
      home: {
        abbreviation: "DET",
        name: "Detroit Lions",
        shortName: "Lions",
        spread: -7,
      },
      away: {
        abbreviation: "TB",
        name: "Tampa Bay Buccaneers",
        shortName: "Buccaneers",
        spread: 7,
      },
      winner: "NULL",
      startTime: "2024-09-15T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "TEN",
        name: "Tennessee Titans",
        shortName: "Titans",
        spread: 3.5,
      },
      away: {
        abbreviation: "NYJ",
        name: "New York Jets",
        shortName: "Jets",
        spread: -3.5,
      },
      winner: "NULL",
      startTime: "2024-09-15T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "NE",
        name: "New England Patriots",
        shortName: "Patriots",
        spread: 3.5,
      },
      away: {
        abbreviation: "SEA",
        name: "Seattle Seahawks",
        shortName: "Seahawks",
        spread: -3.5,
      },
      winner: "NULL",
      startTime: "2024-09-15T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "JAX",
        name: "Jacksonville Jaguars",
        shortName: "Jaguars",
        spread: -3.5,
      },
      away: {
        abbreviation: "CLE",
        name: "Cleveland Browns",
        shortName: "Browns",
        spread: 3.5,
      },
      winner: "NULL",
      startTime: "2024-09-15T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "MIN",
        name: "Minnesota Vikings",
        shortName: "Vikings",
        spread: 6,
      },
      away: {
        abbreviation: "SF",
        name: "San Francisco 49ers",
        shortName: "49ers",
        spread: -6,
      },
      winner: "NULL",
      startTime: "2024-09-15T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "GB",
        name: "Green Bay Packers",
        shortName: "Packers",
        spread: 3,
      },
      away: {
        abbreviation: "IND",
        name: "Indianapolis Colts",
        shortName: "Colts",
        spread: -3,
      },
      winner: "NULL",
      startTime: "2024-09-15T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "CAR",
        name: "Carolina Panthers",
        shortName: "Panthers",
        spread: 6.5,
      },
      away: {
        abbreviation: "LAC",
        name: "Los Angeles Chargers",
        shortName: "Chargers",
        spread: -6.5,
      },
      winner: "NULL",
      startTime: "2024-09-15T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "WSH",
        name: "Washington Commanders",
        shortName: "Commanders",
        spread: -1.5,
      },
      away: {
        abbreviation: "NYG",
        name: "New York Giants",
        shortName: "Giants",
        spread: 1.5,
      },
      winner: "NULL",
      startTime: "2024-09-15T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "BAL",
        name: "Baltimore Ravens",
        shortName: "Ravens",
        spread: -9.5,
      },
      away: {
        abbreviation: "LV",
        name: "Las Vegas Raiders",
        shortName: "Raiders",
        spread: 9.5,
      },
      winner: "NULL",
      startTime: "2024-09-15T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "DAL",
        name: "Dallas Cowboys",
        shortName: "Cowboys",
        spread: -6.5,
      },
      away: {
        abbreviation: "NO",
        name: "New Orleans Saints",
        shortName: "Saints",
        spread: 6.5,
      },
      winner: "NULL",
      startTime: "2024-09-15T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "ARI",
        name: "Arizona Cardinals",
        shortName: "Cardinals",
        spread: -1.5,
      },
      away: {
        abbreviation: "LAR",
        name: "Los Angeles Rams",
        shortName: "Rams",
        spread: 1.5,
      },
      winner: "NULL",
      startTime: "2024-09-15T16:05:00-04:00",
    },
    {
      home: {
        abbreviation: "DEN",
        name: "Denver Broncos",
        shortName: "Broncos",
        spread: 3,
      },
      away: {
        abbreviation: "PIT",
        name: "Pittsburgh Steelers",
        shortName: "Steelers",
        spread: -3,
      },
      winner: "NULL",
      startTime: "2024-09-15T16:25:00-04:00",
    },
    {
      home: {
        abbreviation: "KC",
        name: "Kansas City Chiefs",
        shortName: "Chiefs",
        spread: -6,
      },
      away: {
        abbreviation: "CIN",
        name: "Cincinnati Bengals",
        shortName: "Bengals",
        spread: 6,
      },
      winner: "NULL",
      startTime: "2024-09-15T16:25:00-04:00",
    },
    {
      home: {
        abbreviation: "HOU",
        name: "Houston Texans",
        shortName: "Texans",
        spread: -6.5,
      },
      away: {
        abbreviation: "CHI",
        name: "Chicago Bears",
        shortName: "Bears",
        spread: 6.5,
      },
      winner: "NULL",
      startTime: "2024-09-15T20:20:00-04:00",
    },
    {
      home: {
        abbreviation: "PHI",
        name: "Philadelphia Eagles",
        shortName: "Eagles",
        spread: -6.5,
      },
      away: {
        abbreviation: "ATL",
        name: "Atlanta Falcons",
        shortName: "Falcons",
        spread: 6.5,
      },
      winner: "NULL",
      startTime: "2024-09-16T20:15:00-04:00",
    },
  ],
  3: [
    {
      home: {
        abbreviation: "NYJ",
        name: "New York Jets",
        spread: -6,
      },
      away: {
        abbreviation: "NE",
        name: "New England Patriots",
        spread: 6,
      },
      winner: "NULL",
      startTime: "2024-09-19T20:15:00-04:00",
    },
    {
      home: {
        abbreviation: "TB",
        name: "Tampa Bay Buccaneers",
        spread: -6.5,
      },
      away: {
        abbreviation: "DEN",
        name: "Denver Broncos",
        spread: 6.5,
      },
      winner: "NULL",
      startTime: "2024-09-22T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "MIN",
        name: "Minnesota Vikings",
        spread: 2.5,
      },
      away: {
        abbreviation: "HOU",
        name: "Houston Texans",
        spread: -2.5,
      },
      winner: "NULL",
      startTime: "2024-09-22T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "PIT",
        name: "Pittsburgh Steelers",
        spread: -1.5,
      },
      away: {
        abbreviation: "LAC",
        name: "Los Angeles Chargers",
        spread: 1.5,
      },
      winner: "NULL",
      startTime: "2024-09-22T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "NO",
        name: "New Orleans Saints",
        spread: -2.5,
      },
      away: {
        abbreviation: "PHI",
        name: "Philadelphia Eagles",
        spread: 2.5,
      },
      winner: "NULL",
      startTime: "2024-09-22T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "TEN",
        name: "Tennessee Titans",
        spread: -3,
      },
      away: {
        abbreviation: "GB",
        name: "Green Bay Packers",
        spread: 3,
      },
      winner: "NULL",
      startTime: "2024-09-22T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "CLE",
        name: "Cleveland Browns",
        spread: -6,
      },
      away: {
        abbreviation: "NYG",
        name: "New York Giants",
        spread: 6,
      },
      winner: "NULL",
      startTime: "2024-09-22T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "IND",
        name: "Indianapolis Colts",
        spread: -1,
      },
      away: {
        abbreviation: "CHI",
        name: "Chicago Bears",
        spread: 1,
      },
      winner: "NULL",
      startTime: "2024-09-22T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "SEA",
        name: "Seattle Seahawks",
        spread: -4.5,
      },
      away: {
        abbreviation: "MIA",
        name: "Miami Dolphins",
        spread: 4.5,
      },
      winner: "NULL",
      startTime: "2024-09-22T16:05:00-04:00",
    },
    {
      home: {
        abbreviation: "LV",
        name: "Las Vegas Raiders",
        spread: -5.5,
      },
      away: {
        abbreviation: "CAR",
        name: "Carolina Panthers",
        spread: 5.5,
      },
      winner: "NULL",
      startTime: "2024-09-22T16:05:00-04:00",
    },
    {
      home: {
        abbreviation: "DAL",
        name: "Dallas Cowboys",
        spread: 1.5,
      },
      away: {
        abbreviation: "BAL",
        name: "Baltimore Ravens",
        spread: -1.5,
      },
      winner: "NULL",
      startTime: "2024-09-22T16:25:00-04:00",
    },
    {
      home: {
        abbreviation: "LAR",
        name: "Los Angeles Rams",
        spread: 7.5,
      },
      away: {
        abbreviation: "SF",
        name: "San Francisco 49ers",
        spread: -7.5,
      },
      winner: "NULL",
      startTime: "2024-09-22T16:25:00-04:00",
    },
    {
      home: {
        abbreviation: "ARI",
        name: "Arizona Cardinals",
        spread: 3,
      },
      away: {
        abbreviation: "DET",
        name: "Detroit Lions",
        spread: -3,
      },
      winner: "NULL",
      startTime: "2024-09-22T16:25:00-04:00",
    },
    {
      home: {
        abbreviation: "ATL",
        name: "Atlanta Falcons",
        spread: 3.5,
      },
      away: {
        abbreviation: "KC",
        name: "Kansas City Chiefs",
        spread: -3.5,
      },
      winner: "NULL",
      startTime: "2024-09-22T20:20:00-04:00",
    },
    {
      home: {
        abbreviation: "BUF",
        name: "Buffalo Bills",
        spread: -5.5,
      },
      away: {
        abbreviation: "JAX",
        name: "Jacksonville Jaguars",
        spread: 5.5,
      },
      winner: "NULL",
      startTime: "2024-09-23T19:30:00-04:00",
    },
    {
      home: {
        abbreviation: "CIN",
        name: "Cincinnati Bengals",
        spread: -7.5,
      },
      away: {
        abbreviation: "WSH",
        name: "Washington Commanders",
        spread: 7.5,
      },
      winner: "NULL",
      startTime: "2024-09-23T20:15:00-04:00",
    },
  ],
  4: [
    {
      home: {
        abbreviation: "NYG",
        name: "New York Giants",
        spread: 5.5,
      },
      away: {
        abbreviation: "DAL",
        name: "Dallas Cowboys",
        spread: -5.5,
      },
      winner: "NULL",
      startTime: "2024-09-26T20:15:00-04:00",
    },
    {
      home: {
        abbreviation: "NYJ",
        name: "New York Jets",
        spread: -7.5,
      },
      away: {
        abbreviation: "DEN",
        name: "Denver Broncos",
        spread: 7.5,
      },
      winner: "NULL",
      startTime: "2024-09-29T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "CAR",
        name: "Carolina Panthers",
        spread: 4,
      },
      away: {
        abbreviation: "CIN",
        name: "Cincinnati Bengals",
        spread: -4,
      },
      winner: "NULL",
      startTime: "2024-09-29T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "TB",
        name: "Tampa Bay Buccaneers",
        spread: 2.5,
      },
      away: {
        abbreviation: "PHI",
        name: "Philadelphia Eagles",
        spread: -2.5,
      },
      winner: "NULL",
      startTime: "2024-09-29T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "CHI",
        name: "Chicago Bears",
        spread: -3,
      },
      away: {
        abbreviation: "LAR",
        name: "Los Angeles Rams",
        spread: 3,
      },
      winner: "NULL",
      startTime: "2024-09-29T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "ATL",
        name: "Atlanta Falcons",
        spread: -1.5,
      },
      away: {
        abbreviation: "NO",
        name: "New Orleans Saints",
        spread: 1.5,
      },
      winner: "NULL",
      startTime: "2024-09-29T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "HOU",
        name: "Houston Texans",
        spread: -6.5,
      },
      away: {
        abbreviation: "JAX",
        name: "Jacksonville Jaguars",
        spread: 6.5,
      },
      winner: "NULL",
      startTime: "2024-09-29T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "GB",
        name: "Green Bay Packers",
        spread: -2.5,
      },
      away: {
        abbreviation: "MIN",
        name: "Minnesota Vikings",
        spread: 2.5,
      },
      winner: "NULL",
      startTime: "2024-09-29T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "IND",
        name: "Indianapolis Colts",
        spread: 1.5,
      },
      away: {
        abbreviation: "PIT",
        name: "Pittsburgh Steelers",
        spread: -1.5,
      },
      winner: "NULL",
      startTime: "2024-09-29T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "SF",
        name: "San Francisco 49ers",
        spread: -10,
      },
      away: {
        abbreviation: "NE",
        name: "New England Patriots",
        spread: 10,
      },
      winner: "NULL",
      startTime: "2024-09-29T16:05:00-04:00",
    },
    {
      home: {
        abbreviation: "ARI",
        name: "Arizona Cardinals",
        spread: -3.5,
      },
      away: {
        abbreviation: "WSH",
        name: "Washington Commanders",
        spread: 3.5,
      },
      winner: "NULL",
      startTime: "2024-09-29T16:05:00-04:00",
    },
    {
      home: {
        abbreviation: "LAC",
        name: "Los Angeles Chargers",
        spread: 8,
      },
      away: {
        abbreviation: "KC",
        name: "Kansas City Chiefs",
        spread: -8,
      },
      winner: "NULL",
      startTime: "2024-09-29T16:25:00-04:00",
    },
    {
      home: {
        abbreviation: "LV",
        name: "Las Vegas Raiders",
        spread: -2,
      },
      away: {
        abbreviation: "CLE",
        name: "Cleveland Browns",
        spread: 2,
      },
      winner: "NULL",
      startTime: "2024-09-29T16:25:00-04:00",
    },
    {
      home: {
        abbreviation: "BAL",
        name: "Baltimore Ravens",
        spread: -2.5,
      },
      away: {
        abbreviation: "BUF",
        name: "Buffalo Bills",
        spread: 2.5,
      },
      winner: "NULL",
      startTime: "2024-09-29T20:20:00-04:00",
    },
    {
      home: {
        abbreviation: "MIA",
        name: "Miami Dolphins",
        spread: -1,
      },
      away: {
        abbreviation: "TEN",
        name: "Tennessee Titans",
        spread: 1,
      },
      winner: "NULL",
      startTime: "2024-09-30T19:30:00-04:00",
    },
    {
      home: {
        abbreviation: "DET",
        name: "Detroit Lions",
        spread: -3.5,
      },
      away: {
        abbreviation: "SEA",
        name: "Seattle Seahawks",
        spread: 3.5,
      },
      winner: "NULL",
      startTime: "2024-09-30T20:15:00-04:00",
    },
  ],
  5: [
    {
      home: {
        abbreviation: "ATL",
        name: "Atlanta Falcons",
        spread: -1.5,
      },
      away: {
        abbreviation: "TB",
        name: "Tampa Bay Buccaneers",
        spread: 1.5,
      },
      winner: "NULL",
      startTime: "2024-10-03T20:15:00-04:00",
    },
    {
      home: {
        abbreviation: "MIN",
        name: "Minnesota Vikings",
        spread: -2.5,
      },
      away: {
        abbreviation: "NYJ",
        name: "New York Jets",
        spread: 2.5,
      },
      winner: "NULL",
      startTime: "2024-10-06T09:30:00-04:00",
    },
    {
      home: {
        abbreviation: "NE",
        name: "New England Patriots",
        spread: -1,
      },
      away: {
        abbreviation: "MIA",
        name: "Miami Dolphins",
        spread: 1,
      },
      winner: "NULL",
      startTime: "2024-10-06T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "CHI",
        name: "Chicago Bears",
        spread: -3.5,
      },
      away: {
        abbreviation: "CAR",
        name: "Carolina Panthers",
        spread: 3.5,
      },
      winner: "NULL",
      startTime: "2024-10-06T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "JAX",
        name: "Jacksonville Jaguars",
        spread: -3,
      },
      away: {
        abbreviation: "IND",
        name: "Indianapolis Colts",
        spread: 3,
      },
      winner: "NULL",
      startTime: "2024-10-06T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "HOU",
        name: "Houston Texans",
        spread: 1,
      },
      away: {
        abbreviation: "BUF",
        name: "Buffalo Bills",
        spread: -1,
      },
      winner: "NULL",
      startTime: "2024-10-06T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "CIN",
        name: "Cincinnati Bengals",
        spread: 2.5,
      },
      away: {
        abbreviation: "BAL",
        name: "Baltimore Ravens",
        spread: -2.5,
      },
      winner: "NULL",
      startTime: "2024-10-06T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "WSH",
        name: "Washington Commanders",
        spread: -3,
      },
      away: {
        abbreviation: "CLE",
        name: "Cleveland Browns",
        spread: 3,
      },
      winner: "NULL",
      startTime: "2024-10-06T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "DEN",
        name: "Denver Broncos",
        spread: -3,
      },
      away: {
        abbreviation: "LV",
        name: "Las Vegas Raiders",
        spread: 3,
      },
      winner: "NULL",
      startTime: "2024-10-06T16:05:00-04:00",
    },
    {
      home: {
        abbreviation: "SF",
        name: "San Francisco 49ers",
        spread: -7.5,
      },
      away: {
        abbreviation: "ARI",
        name: "Arizona Cardinals",
        spread: 7.5,
      },
      winner: "NULL",
      startTime: "2024-10-06T16:05:00-04:00",
    },
    {
      home: {
        abbreviation: "SEA",
        name: "Seattle Seahawks",
        spread: -6,
      },
      away: {
        abbreviation: "NYG",
        name: "New York Giants",
        spread: 6,
      },
      winner: "NULL",
      startTime: "2024-10-06T16:25:00-04:00",
    },
    {
      home: {
        abbreviation: "LAR",
        name: "Los Angeles Rams",
        spread: 3.5,
      },
      away: {
        abbreviation: "GB",
        name: "Green Bay Packers",
        spread: -3.5,
      },
      winner: "NULL",
      startTime: "2024-10-06T16:25:00-04:00",
    },
    {
      home: {
        abbreviation: "PIT",
        name: "Pittsburgh Steelers",
        spread: -2.5,
      },
      away: {
        abbreviation: "DAL",
        name: "Dallas Cowboys",
        spread: 2.5,
      },
      winner: "NULL",
      startTime: "2024-10-06T20:20:00-04:00",
    },
    {
      home: {
        abbreviation: "KC",
        name: "Kansas City Chiefs",
        spread: -5,
      },
      away: {
        abbreviation: "NO",
        name: "New Orleans Saints",
        spread: 5,
      },
      winner: "NULL",
      startTime: "2024-10-07T20:15:00-04:00",
    },
  ],
  6: [
    {
      home: {
        abbreviation: "SEA",
        name: "Seattle Seahawks",
        spread: 3.5,
      },
      away: {
        abbreviation: "SF",
        name: "San Francisco 49ers",
        spread: -3.5,
      },
      winner: "NULL",
      startTime: "2024-10-10T20:15:00-04:00",
    },
    {
      home: {
        abbreviation: "CHI",
        name: "Chicago Bears",
        spread: -2.5,
      },
      away: {
        abbreviation: "JAX",
        name: "Jacksonville Jaguars",
        spread: 2.5,
      },
      winner: "NULL",
      startTime: "2024-10-13T09:30:00-04:00",
    },
    {
      home: {
        abbreviation: "BAL",
        name: "Baltimore Ravens",
        spread: -6.5,
      },
      away: {
        abbreviation: "WSH",
        name: "Washington Commanders",
        spread: 6.5,
      },
      winner: "NULL",
      startTime: "2024-10-13T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "NE",
        name: "New England Patriots",
        spread: 7,
      },
      away: {
        abbreviation: "HOU",
        name: "Houston Texans",
        spread: -7,
      },
      winner: "NULL",
      startTime: "2024-10-13T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "PHI",
        name: "Philadelphia Eagles",
        spread: -8.5,
      },
      away: {
        abbreviation: "CLE",
        name: "Cleveland Browns",
        spread: 8.5,
      },
      winner: "NULL",
      startTime: "2024-10-13T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "TEN",
        name: "Tennessee Titans",
        spread: 1,
      },
      away: {
        abbreviation: "IND",
        name: "Indianapolis Colts",
        spread: -1,
      },
      winner: "NULL",
      startTime: "2024-10-13T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "GB",
        name: "Green Bay Packers",
        spread: -5,
      },
      away: {
        abbreviation: "ARI",
        name: "Arizona Cardinals",
        spread: 5,
      },
      winner: "NULL",
      startTime: "2024-10-13T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "NO",
        name: "New Orleans Saints",
        spread: 3.5,
      },
      away: {
        abbreviation: "TB",
        name: "Tampa Bay Buccaneers",
        spread: -3.5,
      },
      winner: "NULL",
      startTime: "2024-10-13T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "LV",
        name: "Las Vegas Raiders",
        spread: 3,
      },
      away: {
        abbreviation: "PIT",
        name: "Pittsburgh Steelers",
        spread: -3,
      },
      winner: "NULL",
      startTime: "2024-10-13T16:05:00-04:00",
    },
    {
      home: {
        abbreviation: "DEN",
        name: "Denver Broncos",
        spread: 3,
      },
      away: {
        abbreviation: "LAC",
        name: "Los Angeles Chargers",
        spread: -3,
      },
      winner: "NULL",
      startTime: "2024-10-13T16:05:00-04:00",
    },
    {
      home: {
        abbreviation: "DAL",
        name: "Dallas Cowboys",
        spread: 3.5,
      },
      away: {
        abbreviation: "DET",
        name: "Detroit Lions",
        spread: -3.5,
      },
      winner: "NULL",
      startTime: "2024-10-13T16:25:00-04:00",
    },
    {
      home: {
        abbreviation: "CAR",
        name: "Carolina Panthers",
        spread: 6,
      },
      away: {
        abbreviation: "ATL",
        name: "Atlanta Falcons",
        spread: -6,
      },
      winner: "NULL",
      startTime: "2024-10-13T16:25:00-04:00",
    },
    {
      home: {
        abbreviation: "NYG",
        name: "New York Giants",
        spread: 3.5,
      },
      away: {
        abbreviation: "CIN",
        name: "Cincinnati Bengals",
        spread: -3.5,
      },
      winner: "NULL",
      startTime: "2024-10-13T20:20:00-04:00",
    },
    {
      home: {
        abbreviation: "NYJ",
        name: "New York Jets",
        spread: 2.5,
      },
      away: {
        abbreviation: "BUF",
        name: "Buffalo Bills",
        spread: -2.5,
      },
      winner: "NULL",
      startTime: "2024-10-14T20:15:00-04:00",
    },
  ],
  7: [
    {
      home: {
        abbreviation: "NO",
        name: "New Orleans Saints",
        spread: 2.5,
      },
      away: {
        abbreviation: "DEN",
        name: "Denver Broncos",
        spread: -2.5,
      },
      winner: "NULL",
      startTime: "2024-10-17T20:15:00-04:00",
    },
    {
      home: {
        abbreviation: "JAX",
        name: "Jacksonville Jaguars",
        spread: -5.5,
      },
      away: {
        abbreviation: "NE",
        name: "New England Patriots",
        spread: 5.5,
      },
      winner: "NULL",
      startTime: "2024-10-20T09:30:00-04:00",
    },
    {
      home: {
        abbreviation: "IND",
        name: "Indianapolis Colts",
        spread: -3.5,
      },
      away: {
        abbreviation: "MIA",
        name: "Miami Dolphins",
        spread: 3.5,
      },
      winner: "NULL",
      startTime: "2024-10-20T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "MIN",
        name: "Minnesota Vikings",
        spread: -2.5,
      },
      away: {
        abbreviation: "DET",
        name: "Detroit Lions",
        spread: 2.5,
      },
      winner: "NULL",
      startTime: "2024-10-20T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "GB",
        name: "Green Bay Packers",
        spread: -3,
      },
      away: {
        abbreviation: "HOU",
        name: "Houston Texans",
        spread: 3,
      },
      winner: "NULL",
      startTime: "2024-10-20T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "NYG",
        name: "New York Giants",
        spread: 3,
      },
      away: {
        abbreviation: "PHI",
        name: "Philadelphia Eagles",
        spread: -3,
      },
      winner: "NULL",
      startTime: "2024-10-20T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "BUF",
        name: "Buffalo Bills",
        spread: -8.5,
      },
      away: {
        abbreviation: "TEN",
        name: "Tennessee Titans",
        spread: 8.5,
      },
      winner: "NULL",
      startTime: "2024-10-20T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "CLE",
        name: "Cleveland Browns",
        spread: 6,
      },
      away: {
        abbreviation: "CIN",
        name: "Cincinnati Bengals",
        spread: -6,
      },
      winner: "NULL",
      startTime: "2024-10-20T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "ATL",
        name: "Atlanta Falcons",
        spread: -3,
      },
      away: {
        abbreviation: "SEA",
        name: "Seattle Seahawks",
        spread: 3,
      },
      winner: "NULL",
      startTime: "2024-10-20T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "WSH",
        name: "Washington Commanders",
        spread: -7.5,
      },
      away: {
        abbreviation: "CAR",
        name: "Carolina Panthers",
        spread: 7.5,
      },
      winner: "NULL",
      startTime: "2024-10-20T16:05:00-04:00",
    },
    {
      home: {
        abbreviation: "LAR",
        name: "Los Angeles Rams",
        spread: -6.5,
      },
      away: {
        abbreviation: "LV",
        name: "Las Vegas Raiders",
        spread: 6.5,
      },
      winner: "NULL",
      startTime: "2024-10-20T16:05:00-04:00",
    },
    {
      home: {
        abbreviation: "SF",
        name: "San Francisco 49ers",
        spread: -1,
      },
      away: {
        abbreviation: "KC",
        name: "Kansas City Chiefs",
        spread: 1,
      },
      winner: "NULL",
      startTime: "2024-10-20T16:25:00-04:00",
    },
    {
      home: {
        abbreviation: "PIT",
        name: "Pittsburgh Steelers",
        spread: 1.5,
      },
      away: {
        abbreviation: "NYJ",
        name: "New York Jets",
        spread: -1.5,
      },
      winner: "NULL",
      startTime: "2024-10-20T20:20:00-04:00",
    },
    {
      home: {
        abbreviation: "TB",
        name: "Tampa Bay Buccaneers",
        spread: 3.5,
      },
      away: {
        abbreviation: "BAL",
        name: "Baltimore Ravens",
        spread: -3.5,
      },
      winner: "NULL",
      startTime: "2024-10-21T20:15:00-04:00",
    },
    {
      home: {
        abbreviation: "ARI",
        name: "Arizona Cardinals",
        spread: 2.5,
      },
      away: {
        abbreviation: "LAC",
        name: "Los Angeles Chargers",
        spread: -2.5,
      },
      winner: "NULL",
      startTime: "2024-10-21T21:00:00-04:00",
    },
  ],
  8: [
    {
      home: {
        abbreviation: "LAR",
        name: "Los Angeles Rams",
        spread: 3,
      },
      away: {
        abbreviation: "MIN",
        name: "Minnesota Vikings",
        spread: -3,
      },
      winner: "NULL",
      startTime: "2024-10-24T20:15:00-04:00",
    },
    {
      home: {
        abbreviation: "CIN",
        name: "Cincinnati Bengals",
        spread: -2.5,
      },
      away: {
        abbreviation: "PHI",
        name: "Philadelphia Eagles",
        spread: 2.5,
      },
      winner: "NULL",
      startTime: "2024-10-27T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "MIA",
        name: "Miami Dolphins",
        spread: -3,
      },
      away: {
        abbreviation: "ARI",
        name: "Arizona Cardinals",
        spread: 3,
      },
      winner: "NULL",
      startTime: "2024-10-27T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "NE",
        name: "New England Patriots",
        spread: 7,
      },
      away: {
        abbreviation: "NYJ",
        name: "New York Jets",
        spread: -7,
      },
      winner: "NULL",
      startTime: "2024-10-27T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "TB",
        name: "Tampa Bay Buccaneers",
        spread: 2.5,
      },
      away: {
        abbreviation: "ATL",
        name: "Atlanta Falcons",
        spread: -2.5,
      },
      winner: "NULL",
      startTime: "2024-10-27T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "DET",
        name: "Detroit Lions",
        spread: -11,
      },
      away: {
        abbreviation: "TEN",
        name: "Tennessee Titans",
        spread: 11,
      },
      winner: "NULL",
      startTime: "2024-10-27T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "JAX",
        name: "Jacksonville Jaguars",
        spread: 4.5,
      },
      away: {
        abbreviation: "GB",
        name: "Green Bay Packers",
        spread: -4.5,
      },
      winner: "NULL",
      startTime: "2024-10-27T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "HOU",
        name: "Houston Texans",
        spread: -6,
      },
      away: {
        abbreviation: "IND",
        name: "Indianapolis Colts",
        spread: 6,
      },
      winner: "NULL",
      startTime: "2024-10-27T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "CLE",
        name: "Cleveland Browns",
        spread: 9,
      },
      away: {
        abbreviation: "BAL",
        name: "Baltimore Ravens",
        spread: -9,
      },
      winner: "NULL",
      startTime: "2024-10-27T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "SEA",
        name: "Seattle Seahawks",
        spread: 3,
      },
      away: {
        abbreviation: "BUF",
        name: "Buffalo Bills",
        spread: -3,
      },
      winner: "NULL",
      startTime: "2024-10-27T16:05:00-04:00",
    },
    {
      home: {
        abbreviation: "LAC",
        name: "Los Angeles Chargers",
        spread: -7.5,
      },
      away: {
        abbreviation: "NO",
        name: "New Orleans Saints",
        spread: 7.5,
      },
      winner: "NULL",
      startTime: "2024-10-27T16:05:00-04:00",
    },
    {
      home: {
        abbreviation: "WSH",
        name: "Washington Commanders",
        spread: 1.5,
      },
      away: {
        abbreviation: "CHI",
        name: "Chicago Bears",
        spread: -1.5,
      },
      winner: "NULL",
      startTime: "2024-10-27T16:25:00-04:00",
    },
    {
      home: {
        abbreviation: "DEN",
        name: "Denver Broncos",
        spread: -9,
      },
      away: {
        abbreviation: "CAR",
        name: "Carolina Panthers",
        spread: 9,
      },
      winner: "NULL",
      startTime: "2024-10-27T16:25:00-04:00",
    },
    {
      home: {
        abbreviation: "LV",
        name: "Las Vegas Raiders",
        spread: 10,
      },
      away: {
        abbreviation: "KC",
        name: "Kansas City Chiefs",
        spread: -10,
      },
      winner: "NULL",
      startTime: "2024-10-27T16:25:00-04:00",
    },
    {
      home: {
        abbreviation: "SF",
        name: "San Francisco 49ers",
        spread: -4.5,
      },
      away: {
        abbreviation: "DAL",
        name: "Dallas Cowboys",
        spread: 4.5,
      },
      winner: "NULL",
      startTime: "2024-10-27T20:20:00-04:00",
    },
    {
      home: {
        abbreviation: "PIT",
        name: "Pittsburgh Steelers",
        spread: -6.5,
      },
      away: {
        abbreviation: "NYG",
        name: "New York Giants",
        spread: 6.5,
      },
      winner: "NULL",
      startTime: "2024-10-28T20:15:00-04:00",
    },
  ],
  9: [
    {
      home: {
        abbreviation: "NYJ",
        name: "New York Jets",
        spread: -2,
      },
      away: {
        abbreviation: "HOU",
        name: "Houston Texans",
        spread: 2,
      },
      winner: "NULL",
      startTime: "2024-10-31T20:15:00-04:00", // Thursday 8:15 PM ET
    },
    {
      home: {
        abbreviation: "ATL",
        name: "Atlanta Falcons",
        spread: -2.5,
      },
      away: {
        abbreviation: "DAL",
        name: "Dallas Cowboys",
        spread: 2.5,
      },
      winner: "NULL",
      startTime: "2024-11-03T13:00:00-05:00", // Sunday 1:00 PM ET
    },
    {
      home: {
        abbreviation: "BAL",
        name: "Baltimore Ravens",
        spread: -9.5,
      },
      away: {
        abbreviation: "DEN",
        name: "Denver Broncos",
        spread: 9.5,
      },
      winner: "NULL",
      startTime: "2024-11-03T13:00:00-05:00", // Sunday 1:00 PM ET
    },
    {
      home: {
        abbreviation: "BUF",
        name: "Buffalo Bills",
        spread: -6,
      },
      away: {
        abbreviation: "MIA",
        name: "Miami Dolphins",
        spread: 6,
      },
      winner: "NULL",
      startTime: "2024-11-03T13:00:00-05:00", // Sunday 1:00 PM ET
    },
    {
      home: {
        abbreviation: "CAR",
        name: "Carolina Panthers",
        spread: 7,
      },
      away: {
        abbreviation: "NO",
        name: "New Orleans Saints",
        spread: -7,
      },
      winner: "NULL",
      startTime: "2024-11-03T13:00:00-05:00", // Sunday 1:00 PM ET
    },
    {
      home: {
        abbreviation: "CIN",
        name: "Cincinnati Bengals",
        spread: -7.5,
      },
      away: {
        abbreviation: "LV",
        name: "Las Vegas Raiders",
        spread: 7.5,
      },
      winner: "NULL",
      startTime: "2024-11-03T13:00:00-05:00", // Sunday 1:00 PM ET
    },
    {
      home: {
        abbreviation: "CLE",
        name: "Cleveland Browns",
        spread: 2,
      },
      away: {
        abbreviation: "LAC",
        name: "Los Angeles Chargers",
        spread: -2,
      },
      winner: "NULL",
      startTime: "2024-11-03T13:00:00-05:00", // Sunday 1:00 PM ET
    },
    {
      home: {
        abbreviation: "NYG",
        name: "New York Giants",
        spread: 3.5,
      },
      away: {
        abbreviation: "WSH",
        name: "Washington Commanders",
        spread: -3.5,
      },
      winner: "NULL",
      startTime: "2024-11-03T13:00:00-05:00", // Sunday 1:00 PM ET
    },
    {
      home: {
        abbreviation: "TEN",
        name: "Tennessee Titans",
        spread: -3.5,
      },
      away: {
        abbreviation: "NE",
        name: "New England Patriots",
        spread: 3.5,
      },
      winner: "NULL",
      startTime: "2024-11-03T13:00:00-05:00", // Sunday 1:00 PM ET
    },
    {
      home: {
        abbreviation: "ARI",
        name: "Arizona Cardinals",
        spread: "E",
      },
      away: {
        abbreviation: "CHI",
        name: "Chicago Bears",
        spread: "E",
      },
      winner: "NULL",
      startTime: "2024-11-03T16:05:00-05:00", // Sunday 4:05 PM ET
    },
    {
      home: {
        abbreviation: "PHI",
        name: "Philadelphia Eagles",
        spread: -7.5,
      },
      away: {
        abbreviation: "JAX",
        name: "Jacksonville Jaguars",
        spread: 7.5,
      },
      winner: "NULL",
      startTime: "2024-11-03T16:05:00-05:00", // Sunday 4:05 PM ET
    },
    {
      home: {
        abbreviation: "GB",
        name: "Green Bay Packers",
        spread: 3.5,
      },
      away: {
        abbreviation: "DET",
        name: "Detroit Lions",
        spread: -3.5,
      },
      winner: "NULL",
      startTime: "2024-11-03T16:25:00-05:00", // Sunday 4:25 PM ET
    },
    {
      home: {
        abbreviation: "SEA",
        name: "Seattle Seahawks",
        spread: 1.5,
      },
      away: {
        abbreviation: "LAR",
        name: "Los Angeles Rams",
        spread: -1.5,
      },
      winner: "NULL",
      startTime: "2024-11-03T16:25:00-05:00", // Sunday 4:25 PM ET
    },
    {
      home: {
        abbreviation: "MIN",
        name: "Minnesota Vikings",
        spread: -5,
      },
      away: {
        abbreviation: "IND",
        name: "Indianapolis Colts",
        spread: 5,
      },
      winner: "NULL",
      startTime: "2024-11-03T20:20:00-05:00", // Sunday 8:20 PM ET
    },
    {
      home: {
        abbreviation: "KC",
        name: "Kansas City Chiefs",
        spread: -8.5,
      },
      away: {
        abbreviation: "TB",
        name: "Tampa Bay Buccaneers",
        spread: 8.5,
      },
      winner: "NULL",
      startTime: "2024-11-04T20:15:00-05:00", // Monday 8:15 PM ET
    },
  ],
  10: [
    {
      home: {
        abbreviation: "BAL",
        name: "Baltimore Ravens",
        spread: -6.0,
      },
      away: {
        abbreviation: "CIN",
        name: "Cincinnati Bengals",
        spread: 6.0,
      },
      winner: "NULL",
      startTime: "2024-11-07T20:15:00-05:00", // Thursday 8:15 PM ET
    },
    {
      home: {
        abbreviation: "CAR",
        name: "Carolina Panthers",
        spread: 6.5,
      },
      away: {
        abbreviation: "NYG",
        name: "New York Giants",
        spread: -6.5,
      },
      winner: "NULL",
      startTime: "2024-11-10T09:30:00-05:00", // Sunday 9:30 AM ET
    },
    {
      home: {
        abbreviation: "CHI",
        name: "Chicago Bears",
        spread: -6.5,
      },
      away: {
        abbreviation: "NE",
        name: "New England Patriots",
        spread: 6.5,
      },
      winner: "NULL",
      startTime: "2024-11-10T13:00:00-05:00", // Sunday 1:00 PM ET
    },
    {
      home: {
        abbreviation: "IND",
        name: "Indianapolis Colts",
        spread: 4.5,
      },
      away: {
        abbreviation: "BUF",
        name: "Buffalo Bills",
        spread: -4.5,
      },
      winner: "NULL",
      startTime: "2024-11-10T13:00:00-05:00", // Sunday 1:00 PM ET
    },
    {
      home: {
        abbreviation: "KC",
        name: "Kansas City Chiefs",
        spread: -9.5,
      },
      away: {
        abbreviation: "DEN",
        name: "Denver Broncos",
        spread: 9.5,
      },
      winner: "NULL",
      startTime: "2024-11-10T13:00:00-05:00", // Sunday 1:00 PM ET
    },
    {
      home: {
        abbreviation: "NO",
        name: "New Orleans Saints",
        spread: 3.5,
      },
      away: {
        abbreviation: "ATL",
        name: "Atlanta Falcons",
        spread: -3.5,
      },
      winner: "NULL",
      startTime: "2024-11-10T13:00:00-05:00", // Sunday 1:00 PM ET
    },
    {
      home: {
        abbreviation: "TB",
        name: "Tampa Bay Buccaneers",
        spread: 6.5,
      },
      away: {
        abbreviation: "SF",
        name: "San Francisco 49ers",
        spread: -6.5,
      },
      winner: "NULL",
      startTime: "2024-11-10T13:00:00-05:00", // Sunday 1:00 PM ET
    },
    {
      home: {
        abbreviation: "WSH",
        name: "Washington Commanders",
        spread: -2.5,
      },
      away: {
        abbreviation: "PIT",
        name: "Pittsburgh Steelers",
        spread: 2.5,
      },
      winner: "NULL",
      startTime: "2024-11-10T13:00:00-05:00", // Sunday 1:00 PM ET
    },
    {
      home: {
        abbreviation: "JAX",
        name: "Jacksonville Jaguars",
        spread: 4.0,
      },
      away: {
        abbreviation: "MIN",
        name: "Minnesota Vikings",
        spread: -4.0,
      },
      winner: "NULL",
      startTime: "2024-11-10T13:00:00-05:00", // Sunday 1:00 PM ET
    },
    {
      home: {
        abbreviation: "LAC",
        name: "Los Angeles Chargers",
        spread: -8.5,
      },
      away: {
        abbreviation: "TEN",
        name: "Tennessee Titans",
        spread: 8.5,
      },
      winner: "NULL",
      startTime: "2024-11-10T16:05:00-05:00", // Sunday 4:05 PM ET
    },
    {
      home: {
        abbreviation: "DAL",
        name: "Dallas Cowboys",
        spread: 6.5,
      },
      away: {
        abbreviation: "PHI",
        name: "Philadelphia Eagles",
        spread: -6.5,
      },
      winner: "NULL",
      startTime: "2024-11-10T16:25:00-05:00", // Sunday 4:25 PM ET
    },
    {
      home: {
        abbreviation: "ARI",
        name: "Arizona Cardinals",
        spread: -1.0,
      },
      away: {
        abbreviation: "NYJ",
        name: "New York Jets",
        spread: 1.0,
      },
      winner: "NULL",
      startTime: "2024-11-10T16:25:00-05:00", // Sunday 4:25 PM ET
    },
    {
      home: {
        abbreviation: "HOU",
        name: "Houston Texans",
        spread: 3.0,
      },
      away: {
        abbreviation: "DET",
        name: "Detroit Lions",
        spread: -3.0,
      },
      winner: "NULL",
      startTime: "2024-11-10T20:20:00-05:00", // Sunday 8:20 PM ET
    },
    {
      home: {
        abbreviation: "LAR",
        name: "Los Angeles Rams",
        spread: -2.5,
      },
      away: {
        abbreviation: "MIA",
        name: "Miami Dolphins",
        spread: 2.5,
      },
      winner: "NULL",
      startTime: "2024-11-11T20:15:00-05:00", // Sunday 8:20 PM ET
    },
  ],
  11: [
    {
      home: {
        abbreviation: "PHI",
        name: "Philadelphia Eagles",
        spread: -3.5,
      },
      away: {
        abbreviation: "WSH",
        name: "Washington Commanders",
        spread: 3.5,
      },
      winner: "NULL",
      startTime: "2024-11-14T20:15:00-05:00",
    },
    {
      home: {
        abbreviation: "CHI",
        name: "Chicago Bears",
        spread: 5,
      },
      away: {
        abbreviation: "GB",
        name: "Green Bay Packers",
        spread: -5,
      },
      winner: "NULL",
      startTime: "2024-11-17T13:00:00-05:00",
    },
    {
      home: {
        abbreviation: "DET",
        name: "Detroit Lions",
        spread: -13.0,
      },
      away: {
        abbreviation: "JAX",
        name: "Jacksonville Jaguars",
        spread: 13.0,
      },
      winner: "NULL",
      startTime: "2024-11-17T13:00:00-05:00",
    },
    {
      home: {
        abbreviation: "MIA",
        name: "Miami Dolphins",
        spread: -7.5,
      },
      away: {
        abbreviation: "LV",
        name: "Las Vegas Raiders",
        spread: 7.5,
      },
      winner: "NULL",
      startTime: "2024-11-17T13:00:00-05:00",
    },
    {
      home: {
        abbreviation: "NE",
        name: "New England Patriots",
        spread: 4.5,
      },
      away: {
        abbreviation: "LAR",
        name: "Los Angeles Rams",
        spread: -4.5,
      },
      winner: "NULL",
      startTime: "2024-11-17T13:00:00-05:00",
    },
    {
      home: {
        abbreviation: "NO",
        name: "New Orleans Saints",
        spread: -1.0,
      },
      away: {
        abbreviation: "CLE",
        name: "Cleveland Browns",
        spread: 1.0,
      },
      winner: "NULL",
      startTime: "2024-11-17T13:00:00-05:00",
    },
    {
      home: {
        abbreviation: "PIT",
        name: "Pittsburgh Steelers",
        spread: 3.5,
      },
      away: {
        abbreviation: "BAL",
        name: "Baltimore Ravens",
        spread: -3.5,
      },
      winner: "NULL",
      startTime: "2024-11-17T13:00:00-05:00",
    },
    {
      home: {
        abbreviation: "TEN",
        name: "Tennessee Titans",
        spread: 6.0,
      },
      away: {
        abbreviation: "MIN",
        name: "Minnesota Vikings",
        spread: -6.0,
      },
      winner: "NULL",
      startTime: "2024-11-17T13:00:00-05:00",
    },
    {
      home: {
        abbreviation: "NYJ",
        name: "New York Jets",
        spread: -3.5,
      },
      away: {
        abbreviation: "IND",
        name: "Indianapolis Colts",
        spread: 3.5,
      },
      winner: "NULL",
      startTime: "2024-11-17T13:00:00-05:00",
    },
    {
      home: {
        abbreviation: "DEN",
        name: "Denver Broncos",
        spread: -2.5,
      },
      away: {
        abbreviation: "ATL",
        name: "Atlanta Falcons",
        spread: 2.5,
      },
      winner: "NULL",
      startTime: "2024-11-17T16:05:00-05:00",
    },
    {
      home: {
        abbreviation: "SF",
        name: "San Francisco 49ers",
        spread: -7.0,
      },
      away: {
        abbreviation: "SEA",
        name: "Seattle Seahawks",
        spread: 7.0,
      },
      winner: "NULL",
      startTime: "2024-11-17T16:05:00-05:00",
    },
    {
      home: {
        abbreviation: "BUF",
        name: "Buffalo Bills",
        spread: -2.5,
      },
      away: {
        abbreviation: "KC",
        name: "Kansas City Chiefs",
        spread: 2.5,
      },
      winner: "NULL",
      startTime: "2024-11-17T16:25:00-05:00",
    },
    {
      home: {
        abbreviation: "LAC",
        name: "Los Angeles Chargers",
        spread: -1.5,
      },
      away: {
        abbreviation: "CIN",
        name: "Cincinnati Bengals",
        spread: 1.5,
      },
      winner: "NULL",
      startTime: "2024-11-17T20:20:00-05:00",
    },
    {
      home: {
        abbreviation: "DAL",
        name: "Dallas Cowboys",
        spread: 7.0,
      },
      away: {
        abbreviation: "HOU",
        name: "Houston Texans",
        spread: -7.0,
      },
      winner: "NULL",
      startTime: "2024-11-18T20:15:00-05:00",
    },
  ],
};
