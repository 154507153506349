const StatCardSkeleton = () => (
  <div className="bg-gray-800 rounded-lg shadow-md p-3 sm:p-4 flex items-center justify-between h-full w-full animate-pulse">
    <div className="rounded-full p-2 bg-gray-700 h-10 w-10"></div>
    <div className="flex flex-col items-end">
      <div className="h-4 w-20 bg-gray-700 rounded mb-2"></div>
      <div className="h-6 w-12 bg-gray-700 rounded"></div>
    </div>
  </div>
);

const WeekCardSkeleton = () => (
  <div className="bg-gray-800 rounded-lg shadow-md overflow-hidden mb-4 animate-pulse">
    <div className="p-4 flex items-center justify-between">
      <div className="flex items-center space-x-4">
        <div className="h-6 w-6 bg-gray-700 rounded"></div>
        <div>
          <div className="h-6 w-24 bg-gray-700 rounded mb-2"></div>
          <div className="h-4 w-32 bg-gray-700 rounded"></div>
        </div>
      </div>
      <div className="h-6 w-6 bg-gray-700 rounded"></div>
    </div>
  </div>
);

const SkeletonLoaderStats = () => {
  return (
    <>
      {/* Stats Grid */}
      <div className="grid grid-cols-2 gap-3 sm:gap-4 mb-6 w-full pt-8">
        <StatCardSkeleton />
        <StatCardSkeleton />
        <StatCardSkeleton />
        <StatCardSkeleton />
      </div>

      {/* Pick History */}
      <div className="flex-grow">
        <div className="h-8 w-36 bg-gray-800 rounded mb-6"></div>
        {[1, 2, 3].map((index) => (
          <WeekCardSkeleton key={index} />
        ))}
      </div>
    </>
  );
};

export default SkeletonLoaderStats;
