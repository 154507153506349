import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Trophy,
  ChevronLeft,
  ChevronRight,
  User,
  ArrowRight,
  ArrowLeft,
} from "lucide-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFootball } from "@fortawesome/free-solid-svg-icons";
import { getGamesForWeek, updateScores } from "../gameData";
import weekDates, { getLatestAvailableWeek, getCurrentWeek } from "./weekDates";
import { supabase } from "../supabaseClient";
import SkeletonLoader from "./SkeletonLoader";
import { motion, AnimatePresence } from "framer-motion";

const ScoresPage = () => {
  const [games, setGames] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState(getLatestAvailableWeek());
  const [userPicks, setUserPicks] = useState({});
  const intervalRef = useRef(null);
  const [, setCurrentWeek] = useState(getCurrentWeek());
  const [showSkeleton, setShowSkeleton] = useState(true);

  const getGameStatus = useCallback((game) => {
    const now = new Date();
    const gameStart = new Date(game.startTime);

    // Check if game hasn't started yet first
    if (now < gameStart) {
      return "UPCOMING";
    }

    // For started games, check if it's completed
    if (game.winner && game.status === "FINAL") {
      return "FINAL";
    }

    // If game has started and has active clock/period info, it's live
    if (game.clock && game.period && game.clock !== "0:00") {
      return `Q${game.period} ${game.clock}`;
    }

    // If game has started but no active stats, show as LIVE
    if (now >= gameStart) {
      return "LIVE";
    }

    return "UPCOMING";
  }, []);

  const updateGameStatuses = useCallback(() => {
    setGames((prevGames) =>
      prevGames.map((game) => ({
        ...game,
        status: getGameStatus(game),
      }))
    );
  }, [getGameStatus]);

  const fetchUserPicks = useCallback(async (week) => {
    try {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (!user) throw new Error("No user logged in");

      const { data, error } = await supabase
        .from("user_picks")
        .select("picks")
        .eq("user_id", user.id)
        .eq("week", week)
        .single();

      if (error) throw error;
      setUserPicks(data.picks || {});
      return data.picks || {};
    } catch (error) {
      console.error("Error fetching user picks:", error);
      setUserPicks({});
      return {};
    }
  }, []);

  const fetchScores = useCallback(
    async (week) => {
      try {
        await updateScores(week);
        const fetchedGames = getGamesForWeek(week);
        const updatedGames = fetchedGames.map((game) => ({
          ...game,
          status: getGameStatus(game),
        }));

        // Update games state smoothly
        setGames((prevGames) => {
          return updatedGames.map((newGame, index) => {
            const prevGame = prevGames[index];
            if (
              prevGame &&
              JSON.stringify(prevGame) !== JSON.stringify(newGame)
            ) {
              return { ...newGame, isUpdated: true };
            }
            return newGame;
          });
        });

        const fetchedUserPicks = await fetchUserPicks(week);
        setUserPicks(fetchedUserPicks);

        // Save to local storage
        localStorage.setItem(`gamesWeek${week}`, JSON.stringify(updatedGames));
        localStorage.setItem(
          `userPicksWeek${week}`,
          JSON.stringify(fetchedUserPicks)
        );
        localStorage.setItem("lastFetchTime", Date.now().toString());
      } catch (error) {
        console.error("Error fetching scores:", error);
      } finally {
        // Ensure the skeleton loader is hidden after fetching
        setShowSkeleton(false);
      }
    },
    [getGameStatus, fetchUserPicks]
  );

  useEffect(() => {
    const cachedGames = localStorage.getItem(`gamesWeek${selectedWeek}`);
    const cachedUserPicks = localStorage.getItem(
      `userPicksWeek${selectedWeek}`
    );
    const lastFetchTime = localStorage.getItem("lastFetchTime");

    if (cachedGames && cachedUserPicks) {
      setGames(JSON.parse(cachedGames));
      setUserPicks(JSON.parse(cachedUserPicks));
      // Show a brief skeleton loader even for cached data
      setShowSkeleton(true);
      setTimeout(() => setShowSkeleton(false), 300);
    } else {
      setShowSkeleton(true);
      fetchScores(selectedWeek);
    }

    const shouldFetch =
      !lastFetchTime || Date.now() - parseInt(lastFetchTime) > 15000; // 1 minute

    if (shouldFetch) {
      fetchScores(selectedWeek);
    }

    // Clear existing intervals
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    // Set new intervals
    intervalRef.current = setInterval(() => {
      fetchScores(selectedWeek);
      updateGameStatuses();
      setCurrentWeek(getCurrentWeek()); // Update current week
    }, 15000); // Update every 30 seconds

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [fetchScores, selectedWeek, updateGameStatuses, setCurrentWeek]);

  const handleWeekChange = (newWeek) => {
    setSelectedWeek(newWeek);
    setShowSkeleton(true);
    fetchScores(newWeek);
  };

  const WeekSelector = () => {
    const weeks = Object.keys(weekDates).map(Number);
    const currentWeekIndex = weeks.indexOf(selectedWeek);

    let visibleWeeks = [];
    if (currentWeekIndex === 0) {
      visibleWeeks = weeks.slice(0, 3);
    } else if (currentWeekIndex === weeks.length - 1) {
      visibleWeeks = weeks.slice(-3);
    } else {
      visibleWeeks = [
        weeks[currentWeekIndex - 1],
        weeks[currentWeekIndex],
        weeks[currentWeekIndex + 1],
      ];
    }

    return (
      <div className="flex items-center justify-center space-x-2 mb-6">
        <button
          onClick={() => handleWeekChange(selectedWeek - 1)}
          className="p-1 rounded-full"
        >
          <ChevronLeft className="w-6 h-6 text-gray-300" />
        </button>
        {visibleWeeks.map((week) => (
          <button
            key={week}
            onClick={() => handleWeekChange(week)}
            className={`px-3 py-1 rounded-md text-sm font-medium ${
              week === selectedWeek ? "bg-blue-600 text-white" : "text-gray-300"
            }`}
          >
            WEEK {week}
          </button>
        ))}
        <button
          onClick={() => handleWeekChange(selectedWeek + 1)}
          className="p-1 rounded-full"
        >
          <ChevronRight className="w-6 h-6 text-gray-300" />
        </button>
      </div>
    );
  };

  const getUserPickForGame = (game) => {
    const gameIndex = games.findIndex(
      (g) =>
        g.away.abbreviation === game.away.abbreviation &&
        g.home.abbreviation === game.home.abbreviation
    );
    return userPicks[gameIndex] || null;
  };

  const renderContent = () => {
    if (showSkeleton) {
      return <SkeletonLoader />;
    }

    const liveGames = games.filter(
      (game) =>
        // Modified this condition to include games with down and distance info
        game.status === "LIVE" ||
        game.status.startsWith("Q") ||
        (game.downDistance && game.downDistance !== "No down and distance info")
    );
    const upcomingGames = games.filter((game) => game.status === "UPCOMING");
    const completedGames = games.filter(
      (game) => game.status === "FINAL" && !game.downDistance // Only truly completed games
    );

    const allGamesCompleted =
      games.length > 0 && games.every((game) => game.status === "FINAL");

    return (
      <>
        {liveGames.length > 0 && !allGamesCompleted && (
          <div className="mb-12">
            <h2 className="text-2xl font-bold text-gray-100 mb-6">Live</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {liveGames.map((game, index) => (
                <GameCard
                  key={index}
                  game={game}
                  userPick={getUserPickForGame(game)}
                />
              ))}
            </div>
          </div>
        )}

        {upcomingGames.length > 0 && !allGamesCompleted && (
          <div className="mb-12">
            <h2 className="text-2xl font-bold text-gray-100 mb-6">Upcoming</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {upcomingGames.map((game, index) => (
                <GameCard
                  key={index}
                  game={game}
                  userPick={getUserPickForGame(game)}
                />
              ))}
            </div>
          </div>
        )}

        {completedGames.length > 0 && (
          <div>
            <h2 className="text-2xl font-bold text-gray-100 mb-6">
              {allGamesCompleted ? "Completed" : "Completed"}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {completedGames.map((game, index) => (
                <GameCard
                  key={index}
                  game={game}
                  userPick={getUserPickForGame(game)}
                  allGamesCompleted={allGamesCompleted}
                />
              ))}
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-6 max-w-3xl relative z-0">
      <WeekSelector />
      <div className="mt-6">{renderContent()}</div>
    </div>
  );
};

const FootballField = ({ downInfo, homeTeam, awayTeam, possession }) => {
  const getYardLine = (downInfo) => {
    const match = downInfo.match(/at (\w+) (\d+)/);
    if (match) {
      const side = match[1].toLowerCase();
      const yardLine = parseInt(match[2]);
      if (side === homeTeam.toLowerCase()) {
        return 100 - yardLine;
      } else {
        return yardLine;
      }
    }
    return 50; // Default to midfield if we can't parse the info
  };

  const yardLine = getYardLine(downInfo);
  const isHomePossession = possession === homeTeam;

  // Determine if the team with possession is in the red zone
  const isRedZone =
    (isHomePossession && yardLine <= 10) ||
    (!isHomePossession && yardLine >= 90);

  return (
    <div className="space-y-1">
      {/* Down and distance info */}
      <div
        className={`text-white text-xs text-center py-1 rounded-t-md ${
          isRedZone ? "bg-red-600" : "bg-gray-900 bg-opacity-50"
        }`}
      >
        {downInfo}
      </div>

      {/* Football field */}
      <div className="flex w-full h-10 rounded-b-md overflow-hidden">
        {/* Away team end zone */}
        <div className="w-[5%] h-full bg-red-600 opacity-50 flex items-center justify-center">
          <span className="text-white text-xs font-bold transform rotate-90">
            {awayTeam}
          </span>
        </div>

        {/* Main field */}
        <div className="relative w-[90%] h-full bg-green-800 border-t-2 border-b-2 border-white">
          {/* Field lines */}
          {[10, 20, 30, 40, 50, 60, 70, 80, 90].map((marker) => (
            <div
              key={marker}
              className={`absolute top-0 bottom-0 w-px ${
                marker === 50 ? "bg-white" : "bg-white opacity-30"
              }`}
              style={{ left: `calc(${marker}% - 1px)` }}
            />
          ))}

          {/* Yard numbers */}
          {[10, 20, 30, 40, 50, 40, 30, 20, 10].map((number, index) => (
            <div
              key={index}
              className={`absolute top-0 transform -translate-y-1/2 text-white text-xs font-bold ${
                number === 50 ? "" : "opacity-70"
              }`}
              style={{
                left: `${index * 10 + 10}%`,
                transform: "translateX(-50%)",
              }}
            >
              {number}
            </div>
          ))}

          {/* Ball position */}
          <div
            className="absolute top-3/4 transform -translate-y-1/2 w-2 h-2 bg-yellow-400 rounded-full transition-all duration-300 ease-in-out"
            style={{
              left: `calc(${yardLine}% - 4.5px)`,
              boxShadow: "0 0 10px 2px rgba(250, 204, 21, 0.7)",
            }}
          />

          {/* Possession arrow */}
          {possession && (
            <div
              className="absolute top-3/4 transform -translate-y-1/2"
              style={{
                left: isHomePossession
                  ? `calc(${yardLine}% - 35px)`
                  : `calc(${yardLine}% - 5px)`,
              }}
            >
              {isHomePossession ? (
                <ArrowLeft className="w-10 h-4 text-yellow-400" />
              ) : (
                <ArrowRight className="w-10 h-4 text-yellow-400" />
              )}
            </div>
          )}
        </div>

        {/* Home team end zone */}
        <div className="w-[5%] h-full bg-blue-600 opacity-50 flex items-center justify-center">
          <span className="text-white text-xs font-bold transform -rotate-90">
            {homeTeam}
          </span>
        </div>
      </div>
    </div>
  );
};

const GameCard = ({ game, userPick, allGamesCompleted }) => {
  const [countdown, setCountdown] = useState("");

  useEffect(() => {
    const updateCountdown = () => {
      const now = new Date();
      const gameStart = new Date(game.startTime);
      const timeUntilStart = gameStart - now;
      const oneHourInMs = 60 * 60 * 1000;

      if (timeUntilStart <= oneHourInMs && timeUntilStart > 0) {
        const minutes = Math.floor(
          (timeUntilStart % oneHourInMs) / (60 * 1000)
        );
        const seconds = Math.floor((timeUntilStart % (60 * 1000)) / 1000);
        setCountdown(`${minutes}:${seconds.toString().padStart(2, "0")}`);
      } else {
        setCountdown("");
      }
    };

    updateCountdown();
    const interval = setInterval(updateCountdown, 1000);
    return () => clearInterval(interval);
  }, [game.startTime]);

  const isLive = game.status !== "FINAL" && game.status !== "UPCOMING";
  const isFinal = game.status === "FINAL";

  return (
    <div
      className={`bg-gray-800 rounded-lg shadow-md overflow-hidden border border-gray-700 transition-shadow duration-300 hover:shadow-lg relative ${
        isFinal && !allGamesCompleted ? "opacity-55" : ""
      } flex flex-col h-full`}
    >
      <div className="bg-gray-750 px-4 py-3 border-b border-gray-700 flex justify-between items-center relative z-10">
        <h2 className="text-sm font-semibold text-gray-200 flex items-center">
          {game.away.abbreviation} @ {game.home.abbreviation}
        </h2>
        <span className="text-xs text-gray-400">
          {isLive ? (
            <span className="text-red-400 font-medium">● LIVE</span>
          ) : countdown ? (
            <span className="text-yellow-400 font-medium">{countdown}</span>
          ) : (
            new Date(game.startTime)
              .toLocaleString("en-US", {
                weekday: "short",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "2-digit",
                timeZone: "America/New_York",
                hour12: true,
              })
              .replace(",", "")
              .toUpperCase() + " EST"
          )}
        </span>
      </div>
      <div className="p-4 space-y-3 relative z-10 flex-grow">
        {[game.away, game.home].map((team, teamIndex) => {
          const isWinner =
            game.winner && game.winner.includes(team.abbreviation);
          const spread =
            team.spread === "E"
              ? "EVEN"
              : parseFloat(team.spread) > 0
              ? `+${team.spread}`
              : team.spread;
          const isUserPick = userPick && userPick.includes(team.abbreviation);
          const hasPossession = game.possession === team.abbreviation;
          return (
            <div key={teamIndex} className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <img
                  src={`https://a.espncdn.com/combiner/i?img=/i/teamlogos/nfl/500/scoreboard/${team.abbreviation.toLowerCase()}.png&scale=crop&cquality=100&location=origin&w=80&h=80`}
                  alt={team.abbreviation}
                  className="w-8 h-8"
                />
                <div className="flex flex-col">
                  <span className="font-medium text-sm">
                    <span
                      className={isWinner ? "text-green-400" : "text-gray-200"}
                    >
                      {team.abbreviation} {team.shortName}
                    </span>
                    <span className="text-gray-400 ml-1">({spread})</span>
                    {isWinner && (
                      <Trophy className="w-4 h-4 ml-2 inline-block text-yellow-400" />
                    )}
                    {isUserPick && (
                      <User className="w-4 h-4 ml-2 inline-block text-blue-400" />
                    )}
                    {hasPossession && (
                      <FontAwesomeIcon
                        icon={faFootball}
                        className="w-3 h-3 ml-2 inline-block text-orange-400"
                      />
                    )}
                  </span>
                  <span className="text-xs text-gray-400">
                    {team.record || "N/A"}
                  </span>
                </div>
              </div>
              <div className="flex items-center">
                <AnimatePresence>
                  <motion.span
                    key={team === game.home ? game.homeScore : game.awayScore}
                    initial={{ opacity: 0, y: -10 }}
                    animate={{
                      opacity: 1,
                      y: 0,
                      color: [
                        isWinner ? "#68D391" : "#F7FAFC", // Start with normal color
                        "#60A5FA", // Animate to blue
                        isWinner ? "#68D391" : "#F7FAFC", // Back to normal color
                      ],
                    }}
                    exit={{ opacity: 0, y: 10 }}
                    transition={{ duration: 0.3, color: { duration: 1 } }}
                    className={`font-semibold text-lg`}
                  >
                    {team === game.home ? game.homeScore : game.awayScore}
                  </motion.span>
                </AnimatePresence>
              </div>
            </div>
          );
        })}
        {game.downDistance &&
          game.downDistance !== "No down and distance info" && (
            <>
              <FootballField
                downInfo={game.downDistance}
                homeTeam={game.home.abbreviation}
                awayTeam={game.away.abbreviation}
                possession={game.possession}
              />
            </>
          )}
        {game.lastPlay && game.lastPlay !== "No last play info" && (
          <div className="text-gray-400 text-xs mt-2">
            <strong>Last Play: </strong>
            {game.lastPlay}
          </div>
        )}
      </div>
      {isLive && (
        <div className="bg-blue-600 text-white py-2 text-sm text-center font-semibold relative z-10 mt-auto">
          {game.status}
        </div>
      )}
      {isFinal && (
        <div className="bg-gray-750 text-gray-400 py-2 text-xs text-center relative z-10 mt-auto">
          {game.winner ? `Cover - ${game.winner}` : "FINAL"}
        </div>
      )}
    </div>
  );
};

export default ScoresPage;
