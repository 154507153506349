import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  ChevronDown,
  ChevronUp,
  Lock,
  ChevronLeft,
  ChevronRight,
  Trophy,
  TrendingUp,
  Target,
} from "lucide-react";
import weekDates, {
  isWeekAvailable,
  getLatestAvailableWeek,
  getCurrentWeek,
} from "./weekDates";
import { supabase } from "../supabaseClient";
import {
  getGamesForWeek,
  isPickCorrect,
  hasGameStarted,
  updateScores,
  hasGameFinished,
  hasLastGameStarted,
  getMondayNightScore,
  hasLastGameFinished,
} from "../gameData";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { useLocation } from "react-router-dom";

const log = (message, data) => {};

const ViewModeSwitch = ({ viewMode, setViewMode }) => {
  return (
    <div className="flex items-center justify-center space-x-2 bg-gray-800 rounded-full p-1 w-80 mx-auto mb-4">
      <button
        className={`flex-1 py-2 px-4 rounded-full text-sm font-medium transition-all duration-200 ${
          viewMode === "week"
            ? "bg-blue-600 text-white"
            : "text-gray-400 hover:text-white"
        }`}
        onClick={() => setViewMode("week")}
      >
        Week
      </button>
      <button
        className={`flex-1 py-2 px-4 rounded-full text-sm font-medium transition-all duration-200 ${
          viewMode === "total"
            ? "bg-blue-600 text-white"
            : "text-gray-400 hover:text-white"
        }`}
        onClick={() => setViewMode("total")}
      >
        Total
      </button>
      <button
        className={`flex-1 py-2 px-4 rounded-full text-sm font-medium transition-all duration-200 relative ${
          viewMode === "compare"
            ? "bg-blue-600 text-white"
            : "text-gray-400 hover:text-white"
        }`}
        onClick={() => setViewMode("compare")}
      >
        Compare
        <span className="absolute -top-2 -right-2 bg-rose-500 text-white text-[10px] px-1.5 py-0.5 rounded-full font-semibold">
          BETA
        </span>
      </button>
    </div>
  );
};

const updateLeaderboard = (
  leaderboardData,
  viewMode,
  calculateRanks,
  selectedWeek,
  currentWeek,
  lastGameFinished
) => {
  log("Updating leaderboard", { viewMode, selectedWeek, currentWeek });
  const mondayNightScore = getMondayNightScore(selectedWeek);
  log("Monday night score", mondayNightScore);

  const sortedData = [...leaderboardData].sort((a, b) => {
    // First, sort by the number of correct picks
    const aScore =
      viewMode === "total" ? a.totalCorrectPicks : a.weeklyCorrectPicks;
    const bScore =
      viewMode === "total" ? b.totalCorrectPicks : b.weeklyCorrectPicks;

    if (bScore !== aScore) {
      return bScore - aScore;
    }

    // If there's a tie, use the tiebreaker
    const aTiebreaker = a.tiebreaker;
    const bTiebreaker = b.tiebreaker;

    if (mondayNightScore !== null) {
      // If both are under or equal to the actual score
      if (aTiebreaker <= mondayNightScore && bTiebreaker <= mondayNightScore) {
        return bTiebreaker - aTiebreaker; // Higher score wins
      }
      // If only a is under or equal
      else if (aTiebreaker <= mondayNightScore) {
        return -1; // a wins
      }
      // If only b is under or equal
      else if (bTiebreaker <= mondayNightScore) {
        return 1; // b wins
      }
      // If both are over
      else {
        return aTiebreaker - bTiebreaker; // Lower score wins
      }
    }

    // If no Monday night score available, sort by tiebreaker (higher is better)
    return bTiebreaker - aTiebreaker;
  });

  log("Sorted data", sortedData);

  const rankedData = calculateRanks(sortedData);
  log("Ranked data", rankedData);

  let winner = null;
  if (
    viewMode === "week" &&
    rankedData.length > 0 &&
    (selectedWeek < currentWeek || lastGameFinished)
  ) {
    winner = rankedData[0].username;
    log("Weekly winner", winner);
  }

  return { rankedData, winner };
};

const SkeletonRow = () => (
  <div className="bg-gray-800 rounded-xl overflow-hidden shadow-lg animate-pulse p-3 sm:p-4">
    <div className="flex items-center justify-between">
      <div className="flex items-center space-x-3 sm:space-x-4">
        <div className="h-12 w-12 sm:h-14 sm:w-14 rounded-full bg-gray-700"></div>
        <div>
          <div className="h-4 w-24 bg-gray-700 rounded"></div>
          <div className="h-3 w-16 bg-gray-700 rounded mt-2"></div>
        </div>
      </div>
      <div className="flex items-center space-x-4 sm:space-x-6">
        <div className="h-8 w-8 bg-gray-700 rounded"></div>
        <div className="h-8 w-8 bg-gray-700 rounded"></div>
      </div>
    </div>
  </div>
);

const ComparisonView = ({
  leaderboard,
  picks,
  selectedWeek,
  lastGameStarted,
}) => {
  const weekGames = getGamesForWeek(selectedWeek);

  return (
    <div className="overflow-x-auto -mx-2 sm:mx-0">
      <table className="w-full border-collapse min-w-[640px]">
        <thead>
          <tr className="border-b border-gray-700">
            <th className="p-2 text-left text-sm font-medium text-gray-400 sticky left-0 bg-gray-900 z-10 w-[120px]">
              User
            </th>
            {weekGames.map((game, index) => (
              <th key={index} className="p-2 px-4 text-center">
                <div className="flex items-center justify-center space-x-1">
                  <img
                    src={`https://a.espncdn.com/combiner/i?img=/i/teamlogos/nfl/500/scoreboard/${game.away.abbreviation.toLowerCase()}.png&scale=crop&cquality=100&location=origin&w=20&h=20`}
                    alt={game.away.name}
                    className="w-4 h-4"
                  />
                  <span className="text-xs text-gray-400">@</span>
                  <img
                    src={`https://a.espncdn.com/combiner/i?img=/i/teamlogos/nfl/500/scoreboard/${game.home.abbreviation.toLowerCase()}.png&scale=crop&cquality=100&location=origin&w=20&h=20`}
                    alt={game.home.name}
                    className="w-4 h-4"
                  />
                </div>
              </th>
            ))}
            <th className="p-2 text-center text-xs font-medium text-gray-400 w-[80px]">
              TB
            </th>
          </tr>
        </thead>
        <tbody>
          {leaderboard.map((user, index) => {
            const userPicks = picks[user.username]?.picks;
            const tiebreaker = picks[user.username]?.tiebreaker;

            return (
              <tr key={user.username} className="border-b border-gray-800">
                <td className="p-2 sticky left-0 bg-gray-900 z-10">
                  <span className="text-sm text-gray-200">{user.username}</span>
                </td>
                {weekGames.map((game, gameIndex) => {
                  const pick = userPicks?.[gameIndex];
                  const gameStarted = hasGameStarted(game.startTime);
                  const gameFinished = hasGameFinished(game);

                  if (!gameStarted) {
                    return (
                      <td key={gameIndex} className="p-2 text-center">
                        <div className="bg-gray-800 text-gray-600 w-16 py-2 rounded text-xs inline-flex items-center justify-center space-x-1">
                          <Lock className="h-3 w-3" />
                        </div>
                      </td>
                    );
                  }

                  if (!pick || pick === "Not Submitted") {
                    return (
                      <td key={gameIndex} className="p-2 text-center">
                        <div className="bg-yellow-900/30 text-yellow-400 w-16 py-0.5 rounded text-xs">
                          -
                        </div>
                      </td>
                    );
                  }

                  const [spread] = pick.split(" ");
                  const isCorrect = gameFinished && isPickCorrect(pick, game);
                  const isPush = gameFinished && game.winner === "PUSH";

                  return (
                    <td key={gameIndex} className="p-2 text-center">
                      <div
                        className={`inline-flex items-center justify-center w-16 py-0.5 rounded text-xs ${
                          !gameFinished
                            ? "bg-gray-800 text-gray-300"
                            : isPush
                            ? "bg-yellow-900/30 text-yellow-400"
                            : isCorrect
                            ? "bg-green-900/30 text-green-400"
                            : "bg-red-900/30 text-red-400"
                        }`}
                      >
                        <span>{spread.replace(/[()]/g, "")}</span>
                      </div>
                    </td>
                  );
                })}
                <td className="p-2 text-center">
                  <span
                    className={`text-xs font-medium ${
                      !lastGameStarted ? "blur-md" : "text-blue-400"
                    }`}
                  >
                    {tiebreaker || "-"}
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const LeaderboardPage = () => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState(getLatestAvailableWeek());
  const [error, setError] = useState(null);
  const [expandedRows, setExpandedRows] = useState([]);
  const [picks, setPicks] = useState({});
  const [weeklyCorrectPicks, setWeeklyCorrectPicks] = useState({});
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const currentWeekRef = useRef(null);
  const selectedWeekRef = useRef(selectedWeek);
  const [currentWeek, setCurrentWeek] = useState(getLatestAvailableWeek());
  const [lastGameStarted, setLastGameStarted] = useState(false);
  const [viewMode, setViewMode] = useState(() => {
    const savedViewMode = localStorage.getItem("leaderboardViewMode");
    return savedViewMode || "week";
  });
  const [weeklyWinner, setWeeklyWinner] = useState(null);
  const [isDataReady, setIsDataReady] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [artificialDelay, setArtificialDelay] = useState(false);
  const [avatarUrls, setAvatarUrls] = useState(() => {
    const cachedAvatarUrls = localStorage.getItem("avatarUrls");
    return cachedAvatarUrls ? JSON.parse(cachedAvatarUrls) : {};
  });
  const [lastGameFinished, setLastGameFinished] = useState(false);
  const [showRouteSkeleton, setShowRouteSkeleton] = useState(false);
  const location = useLocation();

  useEffect(() => {
    selectedWeekRef.current = selectedWeek;
  }, [selectedWeek]);

  const calculateRanks = useCallback(
    (leaderboardData) => {
      let currentRank = 1;
      let previousScore = null;
      let rankIncrement = 0;

      return leaderboardData.map((row, index) => {
        const score =
          viewMode === "total" ? row.totalCorrectPicks : row.weeklyCorrectPicks;
        if (score !== previousScore) {
          currentRank += rankIncrement;
          rankIncrement = 1;
        } else {
          rankIncrement++;
        }
        previousScore = score;
        return { ...row, rank: currentRank };
      });
    },
    [viewMode]
  );

  const fetchLeaderboardData = useCallback(
    async (week) => {
      setIsLoading(true);
      setIsDataReady(false);

      const cachedData = sessionStorage.getItem(`leaderboardData_week_${week}`);
      if (cachedData) {
        const parsedData = JSON.parse(cachedData);
        setLeaderboard(parsedData.leaderboard);
        setPicks(parsedData.picks);
        setWeeklyCorrectPicks(parsedData.weeklyCorrectPicks);
        setIsLoading(false);
        setIsDataReady(true);
        return;
      }

      try {
        // Update scores for all weeks up to the current week
        for (let w = 1; w <= week; w++) {
          await updateScores(w);
        }

        // Fetch all user picks across all weeks
        const { data: allUserPicks, error: allUserPicksError } = await supabase
          .from("user_picks")
          .select("username, week, picks");

        if (allUserPicksError) throw allUserPicksError;

        // Calculate total correct picks and weekly correct picks for each user
        const userCorrectPicks = {};
        const userWeeklyCorrectPicks = {};

        allUserPicks.forEach(({ username, week, picks }) => {
          if (!userCorrectPicks[username]) {
            userCorrectPicks[username] = 0;
          }
          if (!userWeeklyCorrectPicks[username]) {
            userWeeklyCorrectPicks[username] = {};
          }

          const weekGames = getGamesForWeek(week);
          let userPicks = typeof picks === "object" ? picks : JSON.parse(picks);

          userWeeklyCorrectPicks[username][week] = 0;
          Object.entries(userPicks).forEach(([gameIndex, pick]) => {
            const gameResult = weekGames[gameIndex];
            if (!gameResult) {
              console.warn(
                `No game result for week ${week}, game index ${gameIndex}`
              );
              return;
            }
            if (
              hasGameFinished(gameResult) &&
              isPickCorrect(pick, gameResult)
            ) {
              userWeeklyCorrectPicks[username][week] += 1;
              userCorrectPicks[username] += 1; // Increment total correct picks
            }
          });
        });

        const leaderboardWithTiebreakers = Object.entries(userCorrectPicks).map(
          ([username, totalCorrectPicks]) => ({
            username,
            totalCorrectPicks,
            weeklyCorrectPicks: userWeeklyCorrectPicks[username][week] || 0,
            tiebreaker: 0, // You may need to fetch this separately if not available
          })
        );

        // Fetch tiebreaker data for the current week
        const { data: tiebreakerData, error: tiebreakerError } = await supabase
          .from("user_picks")
          .select("username, tiebreaker")
          .eq("week", week);

        if (tiebreakerError) throw tiebreakerError;

        // Update leaderboard with tiebreakers
        const leaderboardWithTiebreakersUpdated =
          leaderboardWithTiebreakers.map((user) => {
            const tiebreakerInfo = tiebreakerData.find(
              (t) => t.username === user.username
            );
            return {
              ...user,
              tiebreaker: tiebreakerInfo ? tiebreakerInfo.tiebreaker : 0,
            };
          });

        // Fetch picks data for the current week
        const { data: picksData, error: picksError } = await supabase
          .from("user_picks")
          .select("username, picks, tiebreaker")
          .eq("week", week);

        if (picksError) throw picksError;

        const picksMap = picksData.reduce((acc, row) => {
          acc[row.username] = {
            picks: row.picks,
            tiebreaker: row.tiebreaker,
          };
          return acc;
        }, {});

        // Fetch avatar URLs if not already available
        if (Object.keys(avatarUrls).length === 0) {
          const { data: avatarData, error: avatarError } = await supabase
            .from("user_avatars")
            .select("username, avatar_url");

          if (avatarError) throw avatarError;

          const newAvatarUrls = avatarData.reduce(
            (acc, { username, avatar_url }) => {
              acc[username] = avatar_url;
              return acc;
            },
            {}
          );

          setAvatarUrls(newAvatarUrls);
          localStorage.setItem("avatarUrls", JSON.stringify(newAvatarUrls));
        }

        // Add avatar URLs to the leaderboard data
        const leaderboardWithAvatars = leaderboardWithTiebreakersUpdated.map(
          (user) => ({
            ...user,
            avatarUrl: avatarUrls[user.username] || null,
          })
        );

        // Check if the last game of the week has finished
        const isLastGameFinished = hasLastGameFinished(week);
        setLastGameFinished(isLastGameFinished);

        // Sort and set leaderboard based on current view mode
        const { rankedData, winner } = updateLeaderboard(
          leaderboardWithAvatars,
          viewMode,
          calculateRanks,
          week,
          currentWeek,
          isLastGameFinished
        );

        const fetchedData = {
          leaderboard: rankedData,
          picks: picksMap,
          weeklyCorrectPicks: userWeeklyCorrectPicks,
        };

        // Store the full leaderboard data in session storage
        sessionStorage.setItem(
          `leaderboardData_week_${week}`,
          JSON.stringify(fetchedData)
        );

        // Update state
        setLeaderboard(rankedData);
        setPicks(picksMap);
        setWeeklyCorrectPicks(fetchedData.weeklyCorrectPicks);
        setWeeklyWinner(winner);
        setIsLoading(false);
        setIsDataReady(true);
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setError("Failed to fetch data. Please try again later.");
        setIsLoading(false);
        setIsDataReady(false);
      }
    },
    [viewMode, calculateRanks, currentWeek, avatarUrls]
  );

  useEffect(() => {
    log("Fetching leaderboard data for selected week", selectedWeek);
    fetchLeaderboardData(selectedWeek);
  }, [fetchLeaderboardData, selectedWeek]);

  useEffect(() => {
    log("Updating leaderboard based on view mode change", viewMode);
    const cachedData = sessionStorage.getItem(
      `leaderboardData_week_${selectedWeek}`
    );
    if (cachedData) {
      const parsedData = JSON.parse(cachedData);
      const { rankedData, winner } = updateLeaderboard(
        parsedData.leaderboard,
        viewMode,
        calculateRanks,
        selectedWeek,
        currentWeek,
        lastGameFinished
      );

      // Preserve avatar URLs when updating leaderboard
      const updatedLeaderboard = rankedData.map((row) => ({
        ...row,
        avatarUrl: avatarUrls[row.username] || row.avatarUrl,
      }));

      setLeaderboard(updatedLeaderboard);
      setWeeklyWinner(winner);
    }
  }, [
    viewMode,
    selectedWeek,
    calculateRanks,
    currentWeek,
    avatarUrls,
    lastGameFinished,
  ]);

  const handleRowClick = useCallback((username) => {
    setExpandedRows((prevRows) => {
      if (prevRows.includes(username)) {
        return prevRows.filter((user) => user !== username);
      } else {
        return [...prevRows, username];
      }
    });
  }, []);

  useEffect(() => {}, [expandedRows]);

  const handleWeekChange = (newWeek) => {
    if (
      (viewMode === "week" || viewMode === "compare") &&
      isWeekAvailable(newWeek) &&
      newWeek !== selectedWeek
    ) {
      setIsLoading(true);
      setIsDataReady(false);
      setArtificialDelay(true);
      setSelectedWeek(newWeek);
      setExpandedRows([]);
      setPicks({});

      // Set a minimum delay for loading effect
      setTimeout(() => {
        setArtificialDelay(false);
        const cachedData = sessionStorage.getItem(
          `leaderboardData_week_${newWeek}`
        );
        if (cachedData) {
          const parsedData = JSON.parse(cachedData);
          setLeaderboard(parsedData.leaderboard);
          setPicks(parsedData.picks);
          setWeeklyCorrectPicks(parsedData.weeklyCorrectPicks);
          setIsLoading(false);
          setIsDataReady(true);
        } else {
          fetchLeaderboardData(newWeek);
        }
      }, 500);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const renderPicks = useCallback(
    (picksData) => {
      if (!picksData || !picksData.picks) {
        return null;
      }

      const picks = picksData.picks;
      const tiebreaker = picksData.tiebreaker;
      const weekGames = getGamesForWeek(selectedWeek);

      return (
        <div className="p-4 rounded-lg">
          <h3 className="text-lg font-bold text-blue-300 mb-4 text-center">
            Week {selectedWeek} Picks
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
            {weekGames.map((gameData, i) => {
              const pick = picks[i];
              const gameStarted = hasGameStarted(gameData.startTime);
              const gameFinished = hasGameFinished(gameData);

              if (!gameStarted) {
                return (
                  <div
                    key={i}
                    className="bg-gray-700 rounded-lg p-3 flex items-center justify-between"
                  >
                    <span className="text-sm font-medium text-gray-400">
                      Game not started
                    </span>
                    <Lock className="h-4 w-4 text-gray-400" />
                  </div>
                );
              }

              if (!pick || pick === "Not Submitted") {
                return (
                  <div
                    key={i}
                    className="bg-gray-700 rounded-lg p-3 flex items-center justify-between border-l-4 border-yellow-500"
                  >
                    <span className="text-sm font-medium text-yellow-400">
                      Not Submitted
                    </span>
                  </div>
                );
              }

              let [teamAbbr, spread] = pick.split(" ");
              spread = spread.replace(/[()]/g, "");
              const team =
                gameData.away.abbreviation === teamAbbr
                  ? gameData.away
                  : gameData.home;

              const isCorrect = gameFinished && isPickCorrect(pick, gameData);
              const isPush = gameFinished && gameData.winner === "PUSH";
              const isNull = !gameData.winner;

              return (
                <div
                  key={i}
                  className={`bg-gray-700 rounded-lg p-3 flex items-center justify-between ${
                    isNull || !gameFinished
                      ? "border-gray-600"
                      : isPush
                      ? "border-yellow-500"
                      : isCorrect
                      ? "border-green-500"
                      : "border-red-500"
                  } border-l-4`}
                >
                  <div className="flex items-center space-x-3">
                    <img
                      src={`https://a.espncdn.com/combiner/i?img=/i/teamlogos/nfl/500/scoreboard/${teamAbbr.toLowerCase()}.png&scale=crop&cquality=100&location=origin&w=100&h=100`}
                      alt={team.name}
                      className="w-8 h-8"
                    />
                    <span className="text-sm font-medium text-gray-200">
                      {team.name}
                    </span>
                  </div>
                  <span className="text-sm font-semibold text-blue-300">
                    {spread}
                  </span>
                </div>
              );
            })}
          </div>
          <div className="mt-4 bg-blue-900 rounded-lg p-3 flex justify-between items-center">
            <span className="text-sm font-semibold text-blue-200">
              Tiebreaker
            </span>
            <span
              className={`text-lg font-bold text-blue-100 ${
                !lastGameStarted ? "blur-md" : ""
              }`}
            >
              {tiebreaker}
            </span>
          </div>
        </div>
      );
    },
    [selectedWeek, lastGameStarted]
  );

  useEffect(() => {
    const updateCurrentWeek = () => {
      const newCurrentWeek = getCurrentWeek();
      if (newCurrentWeek !== currentWeek) {
        setCurrentWeek(newCurrentWeek);
        setSelectedWeek(newCurrentWeek);
      }
    };

    updateCurrentWeek(); // Initial check
    const interval = setInterval(updateCurrentWeek, 60000); // Check every minute

    return () => clearInterval(interval);
  }, [currentWeek]);

  useEffect(() => {
    if (isDropdownOpen && currentWeekRef.current) {
      currentWeekRef.current.scrollIntoView({
        behavior: "auto",
        block: "center",
      });
    }
  }, [isDropdownOpen]);

  useEffect(() => {
    const checkLastGameStarted = () => {
      const hasStarted = hasLastGameStarted(selectedWeek);
      setLastGameStarted(hasStarted);
    };

    checkLastGameStarted();
    const interval = setInterval(checkLastGameStarted, 60000); // Check every minute

    return () => clearInterval(interval);
  }, [selectedWeek]);

  useEffect(() => {
    const checkLastGameFinished = () => {
      const hasFinished = hasLastGameFinished(selectedWeek);
      setLastGameFinished(hasFinished);
    };

    checkLastGameFinished();
    const interval = setInterval(checkLastGameFinished, 60000); // Check every minute

    return () => clearInterval(interval);
  }, [selectedWeek]);

  useEffect(() => {
    setShowRouteSkeleton(true);
    const timer = setTimeout(() => {
      setShowRouteSkeleton(false);
    }, 500); // Adjust this value to control how long the skeleton is shown

    return () => clearTimeout(timer);
  }, [location]);

  useEffect(() => {
    localStorage.setItem("leaderboardViewMode", viewMode);
  }, [viewMode]);

  const WeekSelector = () => {
    const weeks = Object.keys(weekDates).map(Number);
    const currentWeekIndex = weeks.indexOf(selectedWeek);
    const isDisabled = viewMode === "total";

    let visibleWeeks = [];
    if (currentWeekIndex === 0) {
      visibleWeeks = weeks.slice(0, 3);
    } else if (currentWeekIndex === weeks.length - 1) {
      visibleWeeks = weeks.slice(-3);
    } else {
      visibleWeeks = [
        weeks[currentWeekIndex - 1],
        weeks[currentWeekIndex],
        weeks[currentWeekIndex + 1],
      ];
    }

    return (
      <div
        className={`flex items-center justify-center space-x-2 mb-6 ${
          isDisabled ? "opacity-50" : ""
        }`}
      >
        <button
          onClick={() => handleWeekChange(selectedWeek - 1)}
          disabled={isDisabled || !isWeekAvailable(selectedWeek - 1)}
          className="p-1 rounded-full hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <ChevronLeft className="w-6 h-6 text-gray-300" />
        </button>
        {visibleWeeks.map((week) => (
          <button
            key={week}
            onClick={() => handleWeekChange(week)}
            disabled={isDisabled || !isWeekAvailable(week)}
            className={`px-3 py-1 rounded-md text-sm font-medium ${
              week === selectedWeek ? "bg-blue-600 text-white" : "text-gray-300"
            } ${
              !isWeekAvailable(week) || isDisabled
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
          >
            WEEK {week}
          </button>
        ))}
        <button
          onClick={() => handleWeekChange(selectedWeek + 1)}
          disabled={isDisabled || !isWeekAvailable(selectedWeek + 1)}
          className="p-1 rounded-full hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <ChevronRight className="w-6 h-6 text-gray-300" />
        </button>
      </div>
    );
  };

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900">
        <div className="bg-red-900 bg-opacity-50 border border-red-700 rounded-lg p-6">
          <h2 className="text-2xl font-bold text-red-300 mb-3">Error</h2>
          <p className="text-lg text-red-100">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="text-gray-100 min-h-screen">
      <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-6 max-w-3xl relative z-0">
        <WeekSelector />
        <ViewModeSwitch viewMode={viewMode} setViewMode={setViewMode} />

        {showRouteSkeleton ? (
          <div className="space-y-3 sm:space-y-4 mt-4 sm:mt-6">
            {[...Array(10)].map((_, index) => (
              <SkeletonRow key={index} />
            ))}
          </div>
        ) : isLoading || !isDataReady || artificialDelay ? (
          <div className="space-y-3 sm:space-y-4 mt-4 sm:mt-6">
            {[...Array(10)].map((_, index) => (
              <SkeletonRow key={index} />
            ))}
          </div>
        ) : viewMode === "compare" ? (
          <ComparisonView
            leaderboard={leaderboard}
            picks={picks}
            selectedWeek={selectedWeek}
            lastGameStarted={lastGameStarted}
          />
        ) : (
          <div className="space-y-3 sm:space-y-4 mt-4 sm:mt-6">
            {leaderboard.map((row, index) => (
              <div
                key={index}
                className={`bg-gray-800 rounded-xl overflow-hidden shadow-lg  ${
                  viewMode === "week" &&
                  row.username === weeklyWinner &&
                  (selectedWeek < currentWeek || lastGameFinished)
                    ? "border-2 border-yellow-500 shadow-yellow-500/50"
                    : expandedRows.includes(row.username)
                    ? "border-2 border-blue-500 shadow-blue-500/50"
                    : "border border-gray-700 shadow-gray-900/50"
                } hover:shadow-xl`}
                style={{
                  boxShadow:
                    "0 10px 10px -3px rgba(0, 0, 0, 0.5), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                }}
              >
                <div
                  className="p-3 sm:p-4 cursor-pointer"
                  onClick={() => handleRowClick(row.username)}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-3 sm:space-x-4">
                      <div className="relative">
                        <Avatar className="h-12 w-12 sm:h-14 sm:w-14 rounded-full bg-gray-700 border-2 border-blue-500 shadow-md overflow-hidden">
                          <AvatarImage
                            src={row.avatarUrl || "/avatars/01.png"}
                            alt={row.username}
                            className="w-full h-full object-cover"
                          />
                          <AvatarFallback className="flex items-center justify-center text-lg font-bold text-white">
                            {row.username.charAt(0).toUpperCase()}
                          </AvatarFallback>
                        </Avatar>
                      </div>
                      <div>
                        <h2 className="font-bold text-base sm:text-lg text-blue-300 truncate max-w-[150px] sm:max-w-[200px]">
                          {row.username}
                        </h2>
                        <p className="text-xs sm:text-sm text-gray-400 flex items-center mt-0.5">
                          <Trophy className="w-3 h-3 mr-1 text-yellow-500" />
                          Rank: {row.rank}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center space-x-4 sm:space-x-6">
                      <div className="flex flex-col items-center">
                        <span className="text-xs sm:text-sm text-gray-400 mb-0.5 flex items-center">
                          {viewMode === "total" ? (
                            <>
                              <Target className="w-3 h-3 mr-1 text-green-400" />
                              Total
                            </>
                          ) : (
                            <>
                              <TrendingUp className="w-3 h-3 mr-1 text-blue-400" />
                              Week
                            </>
                          )}
                        </span>
                        <span
                          className={`font-semibold text-base sm:text-lg ${
                            viewMode === "total"
                              ? "text-green-400"
                              : "text-blue-400"
                          }`}
                        >
                          {viewMode === "total"
                            ? row.totalCorrectPicks
                            : weeklyCorrectPicks[row.username]?.[
                                selectedWeek
                              ] || 0}
                        </span>
                      </div>
                      <div className="text-blue-400 hover:text-blue-300 transition-colors duration-200">
                        {expandedRows.includes(row.username) ? (
                          <ChevronUp size={20} />
                        ) : (
                          <ChevronDown size={20} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {expandedRows.includes(row.username) && (
                  <div className="px-3 sm:px-4 pb-3 sm:pb-4">
                    {picks[row.username] ? (
                      renderPicks(picks[row.username])
                    ) : (
                      <div className="text-center text-red-400 bg-red-900 bg-opacity-20 rounded-lg p-2 text-xs sm:text-sm">
                        {row.username} has not made picks yet for week{" "}
                        {selectedWeek}.
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default LeaderboardPage;
