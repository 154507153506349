import React, { useState } from "react";
import { Mail, Lock, User, AtSign, CreditCard } from "lucide-react";
import { supabase } from "../supabaseClient";

const SignupModal = ({ isOpen, onClose, onSignup }) => {
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [venmo, setVenmo] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setNotification(null);
    setIsLoading(true);

    try {
      // Step 1: Sign up the user with their actual email
      const { data, error } = await supabase.auth.signUp({
        email: userEmail,
        password,
      });

      if (error) throw error;

      // Step 2: Create a profile
      if (data.user) {
        const { error: profileError } = await supabase
          .from("profiles")
          .upsert({
            id: data.user.id,
            name,
            username,
            venmo,
            email: userEmail,
          })
          .select();

        if (profileError) throw profileError;

        onSignup(username);

        setIsLoading(false);
        setNotification({
          type: "success",
          message:
            "Your account has been created. You will be redirected shortly.",
        });

        // Clear form
        setPassword("");
        setName("");
        setUsername("");
        setVenmo("");
        setUserEmail("");
      }
    } catch (error) {
      console.error("Signup process failed:", error);
      setIsLoading(false);
      setNotification({
        type: "error",
        message: `Error: ${error.message}`,
      });
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center p-4">
      <div className="w-full max-w-md rounded-xl bg-gray-800 shadow-md ring-1 ring-white/5">
        <form onSubmit={handleSubmit} className="p-7 sm:p-11">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-xl font-bold text-white">Sign Up</h2>
            <button
              onClick={isLoading ? null : onClose}
              className={`text-sm font-medium text-gray-400 hover:text-white transition-colors ${
                isLoading ? "cursor-not-allowed" : ""
              }`}
              disabled={isLoading}
            >
              Close
            </button>
          </div>
          {notification && (
            <div
              className={`p-4 mb-6 rounded-md text-sm ${
                notification.type === "success"
                  ? "bg-green-800 text-green-100"
                  : "bg-red-800 text-red-100"
              }`}
            >
              {notification.message}
            </div>
          )}
          <InputField
            id="name"
            label="Name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="John Doe"
            icon={<User className="h-5 w-5 text-gray-400" aria-hidden="true" />}
          />
          <InputField
            id="venmo"
            label="Venmo Username"
            type="text"
            value={venmo}
            onChange={(e) => setVenmo(e.target.value)}
            placeholder="johndoe"
            icon={
              <CreditCard
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            }
          />
          <InputField
            id="userEmail"
            label="Email Address"
            type="email"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            placeholder="johndoe@example.com"
            icon={<Mail className="h-5 w-5 text-gray-400" aria-hidden="true" />}
          />
          <InputField
            id="username"
            label="Choose a Username"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="johndoe123"
            icon={
              <AtSign className="h-5 w-5 text-gray-400" aria-hidden="true" />
            }
          />
          <InputField
            id="password"
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="••••••••"
            icon={<Lock className="h-5 w-5 text-gray-400" aria-hidden="true" />}
          />
          <div className="mt-8">
            <button
              type="submit"
              disabled={isLoading}
              className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white transition-colors ${
                isLoading
                  ? "bg-indigo-400 cursor-not-allowed"
                  : "bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              }`}
            >
              {isLoading ? <Spinner /> : "Sign Up"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const InputField = ({
  id,
  label,
  type,
  value,
  onChange,
  placeholder,
  icon,
}) => (
  <div className="mt-8 space-y-3">
    <label htmlFor={id} className="text-sm/5 font-medium text-gray-300">
      {label}
    </label>
    <div className="relative">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        {icon}
      </div>
      <input
        type={type}
        id={id}
        value={value}
        onChange={onChange}
        required
        autoCapitalize={id === "username" || id === "venmo" ? "off" : "words"}
        className="block w-full pl-10 rounded-lg border border-gray-600 shadow-sm ring-1 ring-gray-700 bg-gray-700 text-white px-3 py-2 text-sm/6 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        placeholder={placeholder}
      />
    </div>
  </div>
);

const Spinner = () => (
  <div className="w-5 h-5 border-4 border-t-4 border-gray-200 border-t-white rounded-full animate-spin"></div>
);

export default SignupModal;