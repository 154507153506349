import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  User,
  Award,
  Percent,
  TrendingUp,
  ChevronDown,
  ChevronUp,
  Calendar,
} from "lucide-react";
import { getLatestAvailableWeek } from "./weekDates";
import { supabase } from "../supabaseClient";
import {
  getGamesForWeek,
  isPickCorrect,
  updateScores,
  hasGameFinished,
} from "../gameData";
import SkeletonLoaderStats from "./SkelStats"; // Add this import at the top

// Clear sessionStorage on initial load if a timestamp is older than a threshold
const THRESHOLD = 1000; // 1 second
const now = new Date().getTime();
const lastVisit = sessionStorage.getItem("lastVisit");
if (!lastVisit || now - lastVisit > THRESHOLD) {
  sessionStorage.clear();
}
sessionStorage.setItem("lastVisit", now);

const StatCard = ({ icon: Icon, title, value }) => (
  <div className="bg-gray-800 rounded-lg shadow-md p-3 sm:p-4 flex items-center justify-between h-full w-full">
    <div className="rounded-full p-2 bg-gray-800 flex-shrink-0">
      <Icon size={20} className="text-blue-400" />
    </div>
    <div className="flex flex-col items-end">
      <h3 className="text-xs sm:text-sm font-semibold text-gray-400 mb-1">
        {title}
      </h3>
      <p className="text-lg sm:text-xl font-bold text-gray-100">{value}</p>
    </div>
  </div>
);

const StatsGrid = ({ userStats }) => (
  <div className="grid grid-cols-2 gap-3 sm:gap-4 mb-6 w-full pt-8">
    <StatCard
      icon={Award}
      title="Correct Picks"
      value={userStats.totalCorrectPicks}
    />
    <StatCard icon={User} title="Total Picks" value={userStats.totalPicks} />
    <StatCard
      icon={Percent}
      title="Win %"
      value={`${userStats.winPercentage}%`}
    />
    <StatCard icon={TrendingUp} title="Ranking" value={`# ${userStats.rank}`} />
  </div>
);

const WeekCard = ({ weekData, isExpanded, onToggle }) => {
  const winRate =
    weekData.finishedPicks > 0 ? weekData.winRate.toFixed(2) : "0.00";

  return (
    <div
      className={`bg-gray-800 rounded-lg shadow-md overflow-hidden mb-4 ${
        isExpanded ? "border border-blue-500" : " hover:border-blue-400"
      }`}
    >
      <div
        className={`p-4 flex items-center justify-between cursor-pointer ${
          isExpanded ? "bg-gray-750" : ""
        }`}
        onClick={onToggle}
      >
        <div className="flex items-center space-x-4">
          <Calendar className="text-blue-400" size={24} />
          <div>
            <h3 className="text-lg font-semibold text-gray-200">
              Week {weekData.week}
            </h3>
            <p className="text-sm text-gray-400">
              {weekData.correctPicks} / {weekData.finishedPicks} correct{" "}
              <span className="text-blue-400">({winRate}%)</span>
            </p>
          </div>
        </div>
        {isExpanded ? (
          <ChevronUp className="text-gray-400" size={24} />
        ) : (
          <ChevronDown className="text-gray-400" size={24} />
        )}
      </div>
      {isExpanded && (
        <div className="p-4 bg-gray-800">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {Object.entries(weekData.picks).map(([gameIndex, pick], index) => {
              const gameData = weekData.games[index];

              // Handle "Not Submitted" case
              if (pick === "Not Submitted") {
                return (
                  <div
                    key={index}
                    className="bg-gray-700 rounded-lg p-3 flex items-center justify-between border-l-4 border-red-600"
                  >
                    <div className="flex items-center space-x-3">
                      <span className="text-sm font-medium text-gray-400">
                        Pick Not Submitted
                      </span>
                    </div>
                  </div>
                );
              }

              const gameFinished = hasGameFinished(gameData);
              const isCorrect = gameFinished && isPickCorrect(pick, gameData);
              const isPush = gameFinished && gameData.winner === "PUSH";
              const isNull = !gameData.winner;
              const gameStarted = new Date(gameData.startTime) <= new Date();

              let [teamAbbr, spread] = pick.split(" ");
              spread = spread.replace(/[()]/g, "");
              const team =
                gameData.away.abbreviation === teamAbbr
                  ? gameData.away
                  : gameData.home;

              return (
                <div
                  key={index}
                  className={`bg-gray-700 rounded-lg p-4 flex flex-col border-l-4   ${
                    !gameStarted
                      ? "border-gray-600"
                      : isNull || !gameFinished
                      ? "border-gray-600"
                      : isPush
                      ? "border-yellow-500"
                      : isCorrect
                      ? "border-green-500"
                      : "border-red-500"
                  }`}
                >
                  <div className="flex items-center justify-between mb-2">
                    <div className="flex items-center space-x-4">
                      <div className="relative w-12 h-12 flex-shrink-0">
                        <img
                          src={`https://a.espncdn.com/combiner/i?img=/i/teamlogos/nfl/500/scoreboard/${teamAbbr.toLowerCase()}.png&scale=crop&cquality=100&location=origin&w=80&h=80`}
                          alt={team.name}
                          className="w-full h-full object-contain"
                        />
                      </div>
                      <div className="flex flex-col">
                        <span className="text-sm font-medium text-gray-200">
                          {team.name}
                        </span>
                        <span className="text-xs text-gray-400">Your Pick</span>
                      </div>
                    </div>
                    <span className="text-lg font-bold text-blue-400">
                      {spread}
                    </span>
                  </div>

                  {gameFinished && (
                    <div className="mt-3 text-sm text-gray-400 border-t border-gray-600 pt-3 space-y-2">
                      <div className="flex justify-between items-center">
                        <span className="flex items-center space-x-2">
                          <span>{gameData.away.abbreviation}</span>
                          <span className="text-gray-500">
                            {gameData.away.name.split(" ").pop()}
                          </span>
                        </span>
                        <span className="font-semibold">
                          {gameData.awayScore || 0}
                        </span>
                      </div>
                      <div className="flex justify-between items-center">
                        <span className="flex items-center space-x-2">
                          <span>{gameData.home.abbreviation}</span>
                          <span className="text-gray-500">
                            {gameData.home.name.split(" ").pop()}
                          </span>
                        </span>
                        <span className="font-semibold">
                          {gameData.homeScore || 0}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          {weekData.finalScorePrediction && (
            <div className="mt-4 bg-gradient-to-r from-blue-900 to-blue-800 rounded-lg p-4 shadow-lg">
              <div className="flex justify-between items-center">
                <span className="text-sm font-semibold text-blue-200">
                  Tiebreaker Prediction
                </span>
                <span className="text-xl font-bold text-blue-100">
                  {weekData.finalScorePrediction}
                </span>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const ProfilePage = ({ userName }) => {
  // Initialize isLoading as true
  const [isLoading, setIsLoading] = useState(true);
  const [userStats, setUserStats] = useState(null);
  const [pickHistory, setPickHistory] = useState([]);
  const [error, setError] = useState(null);
  const [expandedWeeks, setExpandedWeeks] = useState([]);

  const calculateRanks = useCallback((usersTotalCorrectPicks) => {
    const sortedUsers = Object.entries(usersTotalCorrectPicks).sort(
      ([, a], [, b]) => b - a
    );

    let currentRank = 1;
    let previousScore = null;
    let rankIncrement = 0;

    return sortedUsers.reduce((acc, [user, score], index) => {
      if (score !== previousScore) {
        currentRank += rankIncrement;
        rankIncrement = 1;
      } else {
        rankIncrement++;
      }
      previousScore = score;
      acc[user] = currentRank;
      return acc;
    }, {});
  }, []);

  const fetchUserData = useCallback(async () => {
    // Add validation for userName

    const cachedData = sessionStorage.getItem(`profileData_${userName}`);
    if (cachedData) {
      const data = JSON.parse(cachedData);
      setUserStats(data.userStats);
      setPickHistory(data.pickHistory);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    try {
      // Fetch all user picks from Supabase
      const { data: allUserPicks, error: userPicksError } = await supabase
        .from("user_picks")
        .select("*");

      if (userPicksError) throw userPicksError;

      const latestWeek = getLatestAvailableWeek();

      // Update scores for all weeks
      for (let week = 1; week <= latestWeek; week++) {
        await updateScores(week);
      }

      // Calculate total correct picks for all users
      const usersTotalCorrectPicks = {};
      allUserPicks.forEach((pick) => {
        if (pick.week <= latestWeek) {
          const weekGames = getGamesForWeek(pick.week);
          const correctPicks = Object.entries(pick.picks).filter(
            ([gameIndex, teamPick]) =>
              isPickCorrect(teamPick, weekGames[gameIndex])
          ).length;

          if (!usersTotalCorrectPicks[pick.username]) {
            usersTotalCorrectPicks[pick.username] = 0;
          }
          usersTotalCorrectPicks[pick.username] += correctPicks;
        }
      });

      // Calculate ranks with ties
      const userRanks = calculateRanks(usersTotalCorrectPicks);
      const rank = userRanks[userName];

      // Calculate user's stats
      let totalCorrectPicks = 0;
      let totalFinishedPicks = 0;
      const history = [];

      allUserPicks
        .filter((pick) => pick.username === userName)
        .forEach((weekData) => {
          if (weekData.week <= latestWeek) {
            const weekGames = getGamesForWeek(weekData.week);
            const picks = Object.entries(weekData.picks);
            let weekCorrectPicks = 0;
            let weekFinishedPicks = 0;

            picks.forEach(([gameIndex, pick]) => {
              const game = weekGames[gameIndex];
              if (hasGameFinished(game)) {
                weekFinishedPicks++;
                if (isPickCorrect(pick, game)) {
                  weekCorrectPicks++;
                }
              }
            });

            totalCorrectPicks += weekCorrectPicks;
            totalFinishedPicks += weekFinishedPicks;

            history.push({
              week: weekData.week,
              correctPicks: weekCorrectPicks,
              totalPicks: picks.length,
              finishedPicks: weekFinishedPicks,
              winRate:
                weekFinishedPicks > 0
                  ? (weekCorrectPicks / weekFinishedPicks) * 100
                  : 0,
              picks: weekData.picks,
              games: weekGames,
              finalScorePrediction: weekData.tiebreaker,
            });
          }
        });

      // Sort the history array by week number in descending order
      history.sort((a, b) => b.week - a.week);

      const winPercentage =
        totalFinishedPicks > 0
          ? ((totalCorrectPicks / totalFinishedPicks) * 100).toFixed(2)
          : "0.00";

      const fetchedData = {
        userStats: {
          totalCorrectPicks,
          totalPicks: totalFinishedPicks,
          winPercentage,
          rank,
        },
        pickHistory: history,
      };

      sessionStorage.setItem(
        `profileData_${userName}`,
        JSON.stringify(fetchedData)
      );

      setUserStats(fetchedData.userStats);
      setPickHistory(fetchedData.pickHistory);
      setIsLoading(false);
    } catch (err) {
      console.error("Error fetching user data:", err);
      setError("Failed to fetch user data. Please try again later.");
      setIsLoading(false);
    }
  }, [userName, calculateRanks]);

  useEffect(() => {
    // Reset states when userName changes
    setError(null);
    setUserStats(null);
    setPickHistory([]);

    fetchUserData();
  }, [fetchUserData, userName]);

  const toggleWeekExpansion = (week) => {
    setExpandedWeeks((prev) =>
      prev.includes(week) ? prev.filter((w) => w !== week) : [...prev, week]
    );
  };

  return (
    <div className="min-h-screen bg-gray-900 p-2 sm:p-4 md:p-6">
      <div className="w-full max-w-4xl mx-auto">
        {isLoading ? (
          <SkeletonLoaderStats />
        ) : error ? (
          <div className="flex justify-center items-center h-screen">
            <div className="bg-red-900 bg-opacity-50 border border-red-700 rounded-lg p-6">
              <h2 className="text-2xl font-bold text-red-300 mb-3">Error</h2>
              <p className="text-lg text-red-100">{error}</p>
            </div>
          </div>
        ) : (
          <div className="text-gray-100">
            <section className="mb-6">
              {userStats && <StatsGrid userStats={userStats} />}
            </section>

            <div className="flex-grow">
              <h2 className="text-2xl font-semibold mb-4">Pick History</h2>
              {pickHistory.map((week) => (
                <WeekCard
                  key={week.week}
                  weekData={week}
                  isExpanded={expandedWeeks.includes(week.week)}
                  onToggle={() => toggleWeekExpansion(week.week)}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ProfilePage.propTypes = {
  userName: PropTypes.string.isRequired,
};

export default ProfilePage;
