import React, { useMemo } from "react";
import { Dialog, DialogTitle, DialogBody, DialogActions } from "./dialog";
import { Mail, User } from "lucide-react"; // Import the Mail and User icons from lucide-react

const userEmailMap = {
  weedenmh: "weedenmh@gmail.com",
  Weeds11: "robinweeden64@gmail.com",
  Bleet: "ben_leet@icloud.com",
  JeremyBinding: "jeremy.binding20@gmail.com",
  bthomas1090: "bthomas1090@gmail.com",
  anthonyq15: "anthonyquintiliani15@gmail.com",
  dharitos72: "dharitos72@gmail.com",
  daschdegger: "daschdegger@gmail.com",
  remmij: "jamesgagne0@gmail.com",
  pblang: "blangpab@gmail.com",
  Jzebzda: "jackzebzda@gmail.com",
  marvizu: "melesio.arvizu@yahoo.com",
  iengsj: "sydneyieng@yahoo.com",
  TOB: "travisobrien96@gmail.com",
  camgodinsky: "cameron.godinsky@gmail.com",
  lstokes10: "ljstokes10@gmail.com",
  ttardiff20: "ttardiff824@gmail.com",
};

const InboxModal = ({ isOpen, onClose, currentWeek, usersWithoutPicks }) => {
  const emailsWithoutPicks = useMemo(
    () =>
      usersWithoutPicks
        .map((username) => userEmailMap[username])
        .filter(Boolean),
    [usersWithoutPicks]
  );

  const createMailtoLink = (recipients, useBcc = false) => {
    const subject = encodeURIComponent(
      `Reminder: Submit Your Picks for Week ${currentWeek}`
    );
    const body = encodeURIComponent(
      `Hello,\n\nThis is a friendly reminder to submit your picks for Week ${currentWeek}.\n\nThank you!`
    );
    const recipientField = useBcc ? "bcc" : "to";
    const recipientList = recipients.join(",");
    return `mailto:?subject=${subject}&body=${body}&${recipientField}=${recipientList}`;
  };

  const handleRemindAll = () => {
    window.location.href = createMailtoLink(emailsWithoutPicks, true);
  };

  const handleRemindUser = (username) => {
    const email = userEmailMap[username];
    if (email) {
      window.location.href = createMailtoLink([email]);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} size="sm">
      <div className="flex flex-col h-[60vh] sm:h-[450px] w-full max-w-md">
        <DialogTitle className="flex-shrink-0 px-4 py-3 border-b border-gray-700">
          <div className="flex justify-between items-center">
            <h2 className="text-base sm:text-lg font-semibold text-gray-100">
              No Picks:
            </h2>
            <button
              onClick={handleRemindAll}
              className="bg-indigo-600 text-white px-2 py-1 text-xs font-medium rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Remind All
            </button>
          </div>
        </DialogTitle>
        <DialogBody className="flex-grow overflow-y-auto px-3 py-2 sm:px-4 sm:py-3">
          {usersWithoutPicks.length > 0 ? (
            <ul className="space-y-3">
              {usersWithoutPicks.map((username) => (
                <li
                  key={username}
                  className="flex items-center justify-between bg-gray-700 px-3 py-3 sm:px-4 sm:py-3 rounded-md shadow-sm"
                >
                  <div className="flex items-center space-x-2">
                    <User className="text-gray-400 w-3 h-3 sm:w-4 sm:h-4" />
                    <span className="text-xs font-medium text-gray-200">
                      {username}
                    </span>
                  </div>
                  <button
                    onClick={() => handleRemindUser(username)}
                    className="inline-flex items-center bg-indigo-600 text-white px-2 py-1 text-xs font-medium rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <Mail className="mr-1 w-3 h-3" />
                    Remind
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-xs text-gray-300">
              All users have submitted their picks for this week.
            </p>
          )}
        </DialogBody>
        <DialogActions className="flex-shrink-0 px-3 py-2 sm:px-4 sm:py-3 border-t border-gray-700">
          <button
            onClick={onClose}
            className="w-full bg-gray-700 text-gray-200 px-3 py-2 text-sm font-medium rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Close
          </button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default InboxModal;
