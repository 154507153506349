import React, { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";
import { useNavigate } from "react-router-dom";
import { Loader2, Lock, Eye, EyeOff, CheckCircle } from "lucide-react";

function UpdatePasswordPage() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState("");
  const [resetToken, setResetToken] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Prevent zooming on input focus for mobile devices
    const viewport = document.querySelector("meta[name=viewport]");
    if (viewport) {
      viewport.setAttribute(
        "content",
        "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      );
    }

    const checkSession = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (session) {
        setIsLoggedIn(true);
      } else {
        const hash = window.location.hash.substring(1);
        const params = new URLSearchParams(hash);
        const type = params.get("type");
        const accessToken = params.get("access_token");

        if (type === "recovery" && accessToken) {
          setResetToken(accessToken);
        } else {
          setMessage(
            "Invalid or missing reset token. Please try resetting your password again."
          );
          setHasError(true);
        }
      }
    };

    checkSession();

    // Restore viewport on unmount
    return () => {
      if (viewport) {
        viewport.setAttribute(
          "content",
          "width=device-width, initial-scale=1.0"
        );
      }
    };
  }, []);

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwords do not match.");
      setHasError(true);
      return;
    }
    setIsLoading(true);
    setHasError(false);
    try {
      let error;
      if (isLoggedIn) {
        const { error: updateError } = await supabase.auth.updateUser({
          password: password,
        });
        error = updateError;
      } else if (resetToken) {
        const { error: resetError } = await supabase.auth.updateUser(
          { password: password },
          { accessToken: resetToken }
        );
        error = resetError;
      } else {
        throw new Error("No active session or valid reset token");
      }

      if (error) throw error;
      setMessage("Password updated successfully.");
      setIsRedirecting(true);
      setTimeout(() => navigate("/"), 3000);
    } catch (error) {
      setMessage(error.message);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  if (isRedirecting) {
    return (
      <main className="overflow-hidden bg-gradient-to-b from-gray-900 to-gray-800">
        <div className="isolate flex min-h-screen items-center justify-center p-6 lg:p-8">
          <div className="w-full max-w-md rounded-xl bg-gray-800 shadow-md ring-1 ring-white/5 p-7 sm:p-11 text-center">
            <CheckCircle className="mx-auto h-12 w-12 text-green-400 mb-4" />
            <h2 className="text-xl font-semibold text-white mb-2">
              Password Updated Successfully
            </h2>
            <p className="text-gray-400 mb-4">Redirecting you...</p>
            <Loader2 className="animate-spin mx-auto h-6 w-6 text-indigo-500" />
          </div>
        </div>
      </main>
    );
  }

  return (
    <main className="overflow-hidden bg-gradient-to-b from-gray-900 to-gray-800">
      <style jsx>{`
        input,
        textarea,
        select {
          font-size: 16px; /* Prevent zoom on iOS */
        }
      `}</style>
      <div className="isolate flex min-h-screen items-center justify-center p-6 lg:p-8">
        <div className="w-full max-w-md rounded-xl bg-gray-800 shadow-md ring-1 ring-white/5">
          <form onSubmit={handleUpdatePassword} className="p-7 sm:p-11">
            <div className="flex items-start">
              <img src="/logo512.png" alt="Logo" className="h-9 rounded-xl" />
            </div>
            <h1 className="mt-8 text-base/6 font-medium text-white">
              Update Password
            </h1>
            <p className="mt-1 text-sm/5 text-gray-400">
              Enter your new password below.
            </p>
            {message && (
              <p
                className={`mt-4 text-sm ${
                  message.includes("successfully")
                    ? "text-green-400"
                    : "text-red-400"
                }`}
              >
                {message}
              </p>
            )}
            <div className="mt-8 space-y-6">
              <div className="space-y-3">
                <label
                  htmlFor="password"
                  className="text-sm/5 font-medium text-gray-300"
                >
                  New Password
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Lock
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="block w-full pl-10 pr-10 rounded-lg border border-gray-600 shadow-sm ring-1 ring-gray-700 bg-gray-700 text-white px-3 py-2 text-sm/6 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    placeholder="Enter new password"
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center"
                  >
                    {showPassword ? (
                      <EyeOff
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    ) : (
                      <Eye
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    )}
                  </button>
                </div>
              </div>
              <div className="space-y-3">
                <label
                  htmlFor="confirmPassword"
                  className="text-sm/5 font-medium text-gray-300"
                >
                  Confirm New Password
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Lock
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    type={showPassword ? "text" : "password"}
                    required
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="block w-full pl-10 pr-10 rounded-lg border border-gray-600 shadow-sm ring-1 ring-gray-700 bg-gray-700 text-white px-3 py-2 text-sm/6 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    placeholder="Confirm new password"
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center"
                  >
                    {showPassword ? (
                      <EyeOff
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    ) : (
                      <Eye
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    )}
                  </button>
                </div>
              </div>
            </div>
            {hasError ? (
              <div className="mt-8">
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Try Again
                </button>
              </div>
            ) : (
              <div className="mt-8">
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white transition-colors ${
                    isLoading
                      ? "bg-indigo-400 cursor-not-allowed"
                      : "bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  }`}
                >
                  {isLoading ? (
                    <>
                      <Loader2 className="animate-spin mr-2" size={20} />
                      Updating...
                    </>
                  ) : (
                    "Update Password"
                  )}
                </button>
              </div>
            )}
            {isLoggedIn && !hasError && (
              <div className="mt-4">
                {/* <button
                  type="button"
                  onClick={() => navigate("/")}
                  className="w-full flex justify-center py-2 px-4 border border-gray-600 rounded-md shadow-sm text-sm font-medium text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                >
                  Back to Home
                </button> */}
              </div>
            )}
          </form>
        </div>
      </div>
    </main>
  );
}

export default UpdatePasswordPage;