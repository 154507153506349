import React, { useState, useEffect } from "react";
import { X, Download, RefreshCw } from "lucide-react";
import { supabase } from "../supabaseClient";
import { getGamesForWeek, isPickCorrect, hasGameFinished } from "../gameData";

const AdminDashboardModal = ({ isOpen, onClose }) => {
  const [selectedWeek, setSelectedWeek] = useState(1);
  const [isExporting, setIsExporting] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  const handleExportData = async () => {
    setIsExporting(true);
    try {
      const { data: userPicks, error: picksError } = await supabase
        .from("user_picks")
        .select("*")
        .eq("week", selectedWeek);

      if (picksError) throw picksError;

      const weekGames = getGamesForWeek(selectedWeek);

      let leaderboard = [];
      let picks = {};
      let weeklyCorrectPicks = {};

      userPicks.forEach((userPick) => {
        const picksParsed =
          typeof userPick.picks === "string"
            ? JSON.parse(userPick.picks)
            : userPick.picks;
        let correctCount = 0;

        Object.entries(picksParsed).forEach(([gameIndex, pick]) => {
          const gameResult = weekGames[gameIndex];
          if (
            gameResult &&
            hasGameFinished(gameResult) &&
            isPickCorrect(pick, gameResult)
          ) {
            correctCount++;
          }
        });

        leaderboard.push({
          username: userPick.username,
          totalCorrectPicks: userPick.total_correct_picks,
          weeklyCorrectPicks: correctCount,
          avatarUrl: null,
          tiebreaker: userPick.tiebreaker,
        });

        picks[userPick.username] = {
          username: userPick.username,
          picks: picksParsed,
          tiebreaker: userPick.tiebreaker,
        };

        if (!weeklyCorrectPicks[userPick.username]) {
          weeklyCorrectPicks[userPick.username] = {};
        }
        weeklyCorrectPicks[userPick.username][selectedWeek] = correctCount;
      });

      leaderboard.sort((a, b) => b.weeklyCorrectPicks - a.weeklyCorrectPicks);

      let currentRank = 1;
      let previousScore = null;
      leaderboard.forEach((entry, index) => {
        if (entry.weeklyCorrectPicks !== previousScore) {
          currentRank = index + 1;
        }
        entry.rank = currentRank;
        previousScore = entry.weeklyCorrectPicks;
      });

      const exportData = {
        week: selectedWeek,
        leaderboard,
        picks,
        weeklyCorrectPicks,
      };

      const jsonString = JSON.stringify(exportData, null, 2);
      const blob = new Blob([jsonString], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `week_${selectedWeek}_data.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error exporting data:", error);
      // You might want to show an error message to the user here
    } finally {
      setIsExporting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50 backdrop-blur-sm">
      <div className="bg-gray-800 rounded-lg shadow-xl w-full max-w-md mx-auto relative z-10">
        <div className="flex justify-between items-center p-6 border-b border-gray-700">
          <h2 className="text-2xl font-bold text-white">Export Data</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors duration-200"
          >
            <X className="h-6 w-6" />
          </button>
        </div>
        <div className="p-6">
          <div className="mb-6">
            <label
              htmlFor="week"
              className="block text-sm font-medium text-gray-300 mb-2"
            >
              Select Week
            </label>
            <select
              id="week"
              value={selectedWeek}
              onChange={(e) => setSelectedWeek(Number(e.target.value))}
              className="w-full bg-gray-700 text-white rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-200"
            >
              {[...Array(18)].map((_, i) => (
                <option key={i + 1} value={i + 1}>
                  Week {i + 1}
                </option>
              ))}
            </select>
          </div>
          <button
            onClick={handleExportData}
            disabled={isExporting}
            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-md flex items-center justify-center transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isExporting ? (
              <RefreshCw className="animate-spin h-5 w-5 mr-2" />
            ) : (
              <Download className="h-5 w-5 mr-2" />
            )}
            {isExporting ? "Exporting..." : "Export Data"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardModal;
