import React, { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";
import { useNavigate } from "react-router-dom";
import { Mail } from "lucide-react";

function PasswordResetPage() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    // Prevent zooming on input focus for mobile devices
    const viewport = document.querySelector("meta[name=viewport]");
    if (viewport) {
      viewport.setAttribute(
        "content",
        "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      );
    }

    // Restore viewport on unmount
    return () => {
      if (viewport) {
        viewport.setAttribute(
          "content",
          "width=device-width, initial-scale=1.0"
        );
      }
    };
  }, []);

  useEffect(() => {
    let interval;
    if (isLoading) {
      interval = setInterval(() => {
        setProgress((oldProgress) => {
          const newProgress = oldProgress + 2;
          if (newProgress >= 100) {
            clearInterval(interval);
            return 100;
          }
          return newProgress;
        });
      }, 50);
    } else {
      setProgress(0);
    }
    return () => clearInterval(interval);
  }, [isLoading]);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage("Sending reset link...");

    // Simulate a minimum processing time
    const startTime = Date.now();

    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/`,
      });

      if (error) throw error;

      // Ensure the process takes at least 2.5 seconds
      const elapsedTime = Date.now() - startTime;
      if (elapsedTime < 2500) {
        await new Promise((resolve) => setTimeout(resolve, 2500 - elapsedTime));
      }

      setMessage(
        "If an account exists for this email, a password reset link will be sent shortly."
      );
    } catch (error) {
      // Ensure the process takes at least 2.5 seconds even on error
      const elapsedTime = Date.now() - startTime;
      if (elapsedTime < 2500) {
        await new Promise((resolve) => setTimeout(resolve, 2500 - elapsedTime));
      }

      setMessage("An error occurred. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main className="overflow-hidden bg-gradient-to-b from-gray-900 to-gray-800">
      <style jsx>{`
        input,
        textarea,
        select {
          font-size: 16px; /* Prevent zoom on iOS */
        }
        .progress-bar {
          height: 4px;
          background-color: #4f46e5;
          transition: width 0.2s ease-in-out;
        }
      `}</style>
      <div className="isolate flex min-h-screen items-center justify-center p-6 lg:p-8">
        <div className="w-full max-w-md rounded-xl bg-gray-800 shadow-md ring-1 ring-white/5">
          <form onSubmit={handleResetPassword} className="p-7 sm:p-11">
            <div className="flex items-start">
              <img
                src="https://utfs.io/f/7P1pSdZBrdR046DIGmuLynwNRD0JlaZu5KSTQ3rIkPdV8xjo"
                alt="Logo"
                className="h-9 rounded-xl"
              />
            </div>
            <h1 className="mt-8 text-base/6 font-medium text-white">
              Reset Password
            </h1>
            <p className="mt-1 text-sm/5 text-gray-400">
              Enter your email to receive a password reset link.
            </p>
            <div className="mt-8 space-y-3">
              <label
                htmlFor="email"
                className="text-sm/5 font-medium text-gray-300"
              >
                Email
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="block w-full pl-10 rounded-lg border border-gray-600 shadow-sm ring-1 ring-gray-700 bg-gray-700 text-white px-3 py-2 text-sm/6 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  placeholder="Enter your email"
                  autoCapitalize="none"
                  autoCorrect="off"
                  autoComplete="email"
                  spellCheck="false"
                />
              </div>
            </div>
            {isLoading && (
              <div className="mt-4 w-full bg-gray-700 rounded-full h-1">
                <div
                  className="progress-bar rounded-full"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            )}
            {message && (
              <p
                className={`mt-4 text-sm ${
                  message.toLowerCase().includes("if an account exists")
                    ? "text-green-400"
                    : "text-red-400"
                }`}
              >
                {message}
              </p>
            )}
            <div className="mt-8">
              <button
                type="submit"
                disabled={isLoading}
                className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white transition-colors ${
                  isLoading
                    ? "bg-indigo-400 cursor-not-allowed"
                    : "bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                }`}
              >
                {isLoading ? "Sending..." : "Send Reset Link"}
              </button>
            </div>
            <div className="mt-4">
              <button
                onClick={() => navigate("/")}
                className="w-full flex justify-center py-2 px-4 border border-gray-600 rounded-md shadow-sm text-sm font-medium text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              >
                Back to Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
}

export default PasswordResetPage;
