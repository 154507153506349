import React from "react";
import { User, Lock } from "lucide-react";

const AccountSettingsPageSkeleton = () => {
  return (
    <main className="overflow-hidden">
      <div className="isolate flex min-h-screen items-center justify-center py-6 px-2 lg:p-8">
        <div className="w-full max-w-3xl rounded-xl bg-gray-800 shadow-md ring-1 ring-white/5">
          <div className="p-7 sm:p-11">
            <div className="h-8 w-48 bg-gray-700 rounded-lg animate-pulse mb-8" />

            <div className="space-y-8">
              {/* Profile Picture Skeleton */}
              <div className="flex flex-col items-center">
                <div className="relative w-32 h-32 rounded-full overflow-hidden mb-4 ring-2 ring-indigo-500 bg-gray-700 flex items-center justify-center">
                  <User size={48} className="text-gray-600" />
                </div>
              </div>

              {/* User Info Skeleton */}
              <div className="space-y-4">
                {/* Name Field */}
                <div className="space-y-1">
                  <div className="h-4 w-16 bg-gray-700 rounded animate-pulse mb-2" />
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <User
                        className="h-5 w-5 text-gray-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="h-10 w-full bg-gray-700 rounded-lg animate-pulse" />
                  </div>
                </div>

                {/* Username Field */}
                <div className="space-y-1">
                  <div className="h-4 w-24 bg-gray-700 rounded animate-pulse mb-2" />
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <User
                        className="h-5 w-5 text-gray-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="h-10 w-full bg-gray-700 rounded-lg animate-pulse" />
                  </div>
                </div>

                {/* Email Field */}
                <div className="space-y-1">
                  <div className="h-4 w-16 bg-gray-700 rounded animate-pulse mb-2" />
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <User
                        className="h-5 w-5 text-gray-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="h-10 w-full bg-gray-700 rounded-lg animate-pulse" />
                  </div>
                </div>
              </div>

              {/* Separator */}
              <hr className="border-t border-gray-700" />

              {/* Change Password Section Skeleton */}
              <div className="space-y-4">
                <div className="flex items-center">
                  <Lock className="mr-2 text-gray-600" size={20} />
                  <div className="h-6 w-40 bg-gray-700 rounded animate-pulse" />
                </div>

                <div className="space-y-4">
                  {[1, 2, 3].map((index) => (
                    <div key={index} className="space-y-1">
                      <div className="h-4 w-32 bg-gray-700 rounded animate-pulse mb-2" />
                      <div className="h-10 w-full bg-gray-700 rounded-lg animate-pulse" />
                    </div>
                  ))}
                  <div className="h-10 w-full bg-gray-700 rounded-md animate-pulse mt-4" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AccountSettingsPageSkeleton;
