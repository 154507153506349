import React, { useEffect, useState } from "react";
import { Mail } from "lucide-react";

const MaintenancePage = () => {
  const [requestNumber, setRequestNumber] = useState("");

  useEffect(() => {
    // Disable scrolling when the component mounts
    document.body.style.overflow = "hidden";

    // Generate a random 4-digit number
    const randomNumber = Math.floor(1000 + Math.random() * 9000);
    setRequestNumber(randomNumber.toString());

    // Re-enable scrolling when the component unmounts
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const emailSubject = `Support Request #${requestNumber}`;
  const emailLink = `mailto:blangpab@gmail.com?subject=${encodeURIComponent(
    emailSubject
  )}`;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 p-4">
      <div className="w-full max-w-md space-y-8 p-6 sm:p-10 bg-gray-800 rounded-xl shadow-lg text-center">
        <div>
          <h2 className="text-2xl sm:text-3xl font-extrabold text-white">
            Site Maintenance
          </h2>
          <p className="mt-2 text-sm text-gray-400">
            We're currently performing some maintenance. We are experiencing some issues with incorrect spreads displaying for Week 11. We'll be back shortly!
          </p>
        </div>
        <div className="mt-8 space-y-6">
          <div className="flex items-center justify-center">
            <svg
              className="animate-spin h-10 w-10 text-indigo-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
          <p className="text-xs text-gray-400">
            We appreciate your patience. If you need immediate assistance,
            please contact our support team.
          </p>
          <a
            href={emailLink}
            className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <Mail className="w-5 h-5 mr-2" />
            Contact Support
          </a>
        </div>
      </div>
    </div>
  );
};

export default MaintenancePage;
