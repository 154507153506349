import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Bell,
  BarChart2,
  LogOut,
  Settings,
  AlertCircle,
  ChevronDown,
  User,
} from "lucide-react";
import { DevicePhoneMobileIcon } from "@heroicons/react/24/outline";
import AddToHome from "./AddToHome";
import { getCurrentWeek } from "./weekDates";
import InboxModal from "./InboxModal";
import { supabase } from "../supabaseClient";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";

import AdminDashboardModal from "./AdminDashboardModal";

const USERNAMES = [
  "weedenmh",
  "Weeds11",
  "Bleet",
  "JeremyBinding",
  "bthomas1090",
  "anthonyq15",
  "dharitos72",
  "daschdegger",
  "remmij",
  "pblang",
  "Jzebzda",
  "marvizu",
  "iengsj",
  "TOB",
  "camgodinsky",
  "lstokes10",
  "ttardiff20",
];

const Header = ({
  userName,
  onLogout,
  setUserName,
  avatarUrl,
  hasSubmittedAllPicks,
}) => {
  const [isAddToHomeOpen, setIsAddToHomeOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isStandalone, setIsStandalone] = useState(false);
  const [isInboxOpen, setIsInboxOpen] = useState(false);
  const [isAdminDashboardOpen, setIsAdminDashboardOpen] = useState(false);

  const [hasNotification, setHasNotification] = useState(false);
  const [usersWithoutPicks, setUsersWithoutPicks] = useState([]);
  const navigate = useNavigate();
  const currentWeek = getCurrentWeek();

  const fetchUsersWithoutPicks = useCallback(async () => {
    try {
      const { data: usersWithPicks, error: picksError } = await supabase
        .from("user_picks")
        .select("username")
        .eq("week", currentWeek);

      if (picksError) throw picksError;

      const usernamesWithPicks = new Set(
        usersWithPicks.map((user) => user.username.toLowerCase())
      );

      const usersWithoutPicks = USERNAMES.filter(
        (username) => !usernamesWithPicks.has(username.toLowerCase())
      );

      setUsersWithoutPicks(usersWithoutPicks);
      setHasNotification(usersWithoutPicks.length > 0);
    } catch (error) {
      console.error("Error fetching users without picks:", error);
    }
  }, [currentWeek]);

  useEffect(() => {
    const checkIfMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      setIsMobile(
        /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
          userAgent.toLowerCase()
        )
      );
    };

    const checkIfStandalone = () => {
      setIsStandalone(window.matchMedia("(display-mode: standalone)").matches);
    };

    checkIfMobile();
    checkIfStandalone();
    window.addEventListener("resize", checkIfMobile);
    window.addEventListener("resize", checkIfStandalone);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
      window.removeEventListener("resize", checkIfStandalone);
    };
  }, []);

  useEffect(() => {
    if (userName === "pblang") {
      fetchUsersWithoutPicks();
    }
  }, [userName, fetchUsersWithoutPicks]);

  useEffect(() => {
    if (isMobile && !isStandalone) {
      const img = new Image();
      img.src =
        "https://utfs.io/f/7P1pSdZBrdR0FgO9FWUNWSIUAYLoKjlQ5nqeu9ygEHwsMhar";
    }
  }, [isMobile, isStandalone]);

  const openAddToHome = () => {
    setIsAddToHomeOpen(true);
  };

  const closeAddToHome = () => {
    setIsAddToHomeOpen(false);
  };

  const handleProfileClick = useCallback(() => {
    navigate("/profile");
  }, [navigate]);

  const handleAccountSettingsClick = useCallback(() => {
    navigate("/account-settings");
  }, [navigate]);

  const openInbox = () => {
    setIsInboxOpen(true);
  };

  const closeInbox = () => {
    setIsInboxOpen(false);
  };

  const openAdminDashboard = () => {
    setIsAdminDashboardOpen(true);
  };

  const closeAdminDashboard = () => {
    setIsAdminDashboardOpen(false);
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <>
      {isDropdownOpen && (
        <div
          className="fixed inset-0 z-40"
          onClick={() => setIsDropdownOpen(false)}
        />
      )}
      <header
        className="fixed top-0 left-0 right-0 bg-gray-900/95 backdrop-blur-lg text-white border-b border-gray-800/50 z-50"
        style={{
          paddingTop: "env(safe-area-inset-top)",
          height: "calc(70px + env(safe-area-inset-top))",
        }}
      >
        <div className="container mx-auto px-4 py-2 h-full">
          <div className="flex items-center justify-between h-full">
            {/* Left section */}
            <div className="w-32">
              {" "}
              {/* Fixed width to balance with right section */}
              <h1 className="text-2xl font-bold font-heading flex items-center">
                <img
                  src="https://utfs.io/f/7P1pSdZBrdR0FgO9FWUNWSIUAYLoKjlQ5nqeu9ygEHwsMhar"
                  alt="Logo"
                  className="h-12 w-12 rounded-full cursor-pointer"
                  onClick={() => navigate("/")}
                />
              </h1>
            </div>

            {/* Right section */}
            <div className="w-32 flex justify-end">
              {" "}
              {/* Fixed width to balance with left section */}
              <div className="flex items-center space-x-5">
                {userName === "pblang" && (
                  <button
                    onClick={openInbox}
                    className="p-2 rounded-full relative"
                    aria-label="Open notifications"
                    aria-haspopup="true"
                    aria-expanded={isInboxOpen}
                  >
                    <Bell className="h-5 w-5 text-gray-300" />
                    {hasNotification && (
                      <span
                        className="absolute top-0 right-0 block h-2.5 w-2.5 rounded-full bg-red-500 ring-2 ring-gray-900 animate-pulse"
                        aria-label="New notifications available"
                      />
                    )}
                  </button>
                )}
                <DropdownMenu
                  modal={false}
                  open={isDropdownOpen}
                  onOpenChange={setIsDropdownOpen}
                >
                  <DropdownMenuTrigger asChild>
                    <button className="flex items-center space-x-3 bg-gray-800/50 text-sm rounded-full px-4 py-2 focus:outline-none border border-gray-700/30">
                      <User className="h-5 w-5 text-gray-200" />
                      {!hasSubmittedAllPicks && (
                        <AlertCircle className="h-5 w-5 text-yellow-400" />
                      )}
                      <ChevronDown className="h-4 w-4 text-gray-400" />
                    </button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent
                    className="w-64 bg-slate-800/95 backdrop-blur-sm rounded-2xl shadow-xl py-3 text-white border border-gray-600"
                    align="end"
                    sideOffset={8}
                    style={{ zIndex: 50 }}
                    // Add these animation classes
                    asChild
                  >
                    <div className="animate-in fade-in-0 zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=top]:slide-in-from-bottom-2 data-[side=right]:slide-in-from-left-2 data-[side=left]:slide-in-from-right-2">
                      {/* Move all your dropdown content here */}
                      {!hasSubmittedAllPicks && (
                        <>
                          <DropdownMenuItem
                            onClick={() => navigate("/home")}
                            className="px-3 py-3 text-sm hover:bg-yellow-500/10 focus:bg-yellow-500/10 flex items-center mx-2 cursor-pointer transition-all duration-200 rounded-lg group border border-yellow-500/30 mb-3"
                          >
                            <div className="bg-yellow-500/10 p-2 rounded-lg mr-3 group-hover:bg-yellow-500/20 transition-colors duration-200">
                              <AlertCircle className="h-5 w-5 text-yellow-400" />
                            </div>
                            <div className="flex flex-col">
                              <span className="text-yellow-400 font-semibold">
                                Incomplete picks
                              </span>
                              <span className="text-xs text-gray-300 mt-0.5">
                                Click to make your picks
                              </span>
                            </div>
                          </DropdownMenuItem>
                          <DropdownMenuSeparator className="my-2 border-t border-gray-700/30" />
                        </>
                      )}

                      <div className="px-3 pb-2 mb-2 border-b border-gray-600">
                        <div className="flex items-center space-x-3">
                          <Avatar className="h-10 w-10 shrink-0 aspect-square">
                            <AvatarImage
                              src={avatarUrl || "/avatars/01.png"}
                              alt={userName}
                              className="object-cover"
                            />
                            <AvatarFallback className="aspect-square">
                              {userName.charAt(0).toUpperCase()}
                            </AvatarFallback>
                          </Avatar>
                          <div>
                            <p className="text-sm font-medium text-gray-200">
                              {userName}
                            </p>
                          </div>
                        </div>
                      </div>

                      <DropdownMenuItem
                        onClick={handleProfileClick}
                        className="px-3 py-2.5 text-sm hover:bg-gray-700/50 focus:bg-gray-700/50 flex items-center mx-2 cursor-pointer transition-all duration-200 rounded-lg group"
                      >
                        <div className="bg-gray-700/50 p-2 rounded-lg mr-3 group-hover:bg-gray-600/50 transition-colors duration-200">
                          <BarChart2 className="h-4 w-4 text-blue-400" />
                        </div>
                        <span className="text-gray-200 group-hover:text-white transition-colors duration-200">
                          Stats
                        </span>
                      </DropdownMenuItem>

                      <DropdownMenuItem
                        onClick={handleAccountSettingsClick}
                        className="px-3 py-2.5 text-sm hover:bg-gray-700/50 focus:bg-gray-700/50 flex items-center mx-2 cursor-pointer transition-all duration-200 rounded-lg group"
                      >
                        <div className="bg-gray-700/50 p-2 rounded-lg mr-3 group-hover:bg-gray-600/50 transition-colors duration-200">
                          <Settings className="h-4 w-4 text-emerald-400" />
                        </div>
                        <span>Settings</span>
                      </DropdownMenuItem>

                      {isMobile && !isStandalone && (
                        <>
                          <DropdownMenuSeparator className="my-2 border-t border-gray-700/30" />
                          <DropdownMenuItem
                            onClick={openAddToHome}
                            className="px-3 py-2.5 text-sm hover:bg-gray-700/50 focus:bg-gray-700/50 flex items-center mx-2 cursor-pointer transition-all duration-200 rounded-lg group"
                          >
                            <div className="bg-gray-700/50 p-2 rounded-lg mr-3 group-hover:bg-gray-600/50 transition-colors duration-200">
                              <DevicePhoneMobileIcon className="h-4 w-4 text-purple-400" />
                            </div>
                            <span>Get the app</span>
                          </DropdownMenuItem>
                        </>
                      )}

                      {userName === "pblang" && (
                        <DropdownMenuItem
                          onClick={openAdminDashboard}
                          className="px-3 py-2.5 text-sm hover:bg-gray-700/50 focus:bg-gray-700/50 flex items-center mx-2 cursor-pointer transition-all duration-200 rounded-lg group"
                        >
                          <div className="bg-gray-700/50 p-2 rounded-lg mr-3 group-hover:bg-gray-600/50 transition-colors duration-200">
                            <BarChart2 className="h-4 w-4 text-indigo-400" />
                          </div>
                          <span>Admin Dashboard</span>
                        </DropdownMenuItem>
                      )}

                      <DropdownMenuSeparator className="my-2 border-t border-gray-700/30" />

                      <DropdownMenuItem
                        onClick={onLogout}
                        className="px-3 py-2.5 text-sm hover:bg-gray-700/50 focus:bg-gray-700/50 flex items-center mx-2 cursor-pointer transition-all duration-200 rounded-lg group text-red-400"
                      >
                        <div className="bg-red-500/10 p-2 rounded-lg mr-3 group-hover:bg-red-500/20 transition-colors duration-200">
                          <LogOut className="h-4 w-4 text-red-400" />
                        </div>
                        <span>Log out</span>
                      </DropdownMenuItem>
                    </div>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>
          </div>
        </div>
      </header>
      <AddToHome isOpen={isAddToHomeOpen} onClose={closeAddToHome} />
      {userName === "pblang" && (
        <InboxModal
          isOpen={isInboxOpen}
          onClose={closeInbox}
          currentWeek={currentWeek}
          usersWithoutPicks={usersWithoutPicks}
        />
      )}
      {userName === "pblang" && (
        <AdminDashboardModal
          isOpen={isAdminDashboardOpen}
          onClose={closeAdminDashboard}
        />
      )}
    </>
  );
};

export default Header;
