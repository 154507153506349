import React from "react";

const SkeletonGameCard = () => (
  <div className="bg-gray-800 rounded-lg shadow-md overflow-hidden border border-gray-700 animate-pulse">
    <div className="bg-gray-750 h-12 border-b border-gray-700"></div>
    <div className="p-4 space-y-4">
      <div className="flex items-center space-x-3">
        <div className="w-8 h-8 bg-gray-700 rounded-full"></div>
        <div className="flex-1 space-y-2">
          <div className="h-4 bg-gray-700 rounded w-3/4"></div>
          <div className="h-3 bg-gray-700 rounded w-1/2"></div>
        </div>
        <div className="w-8 h-8 bg-gray-700 rounded"></div>
      </div>
      <div className="flex items-center space-x-3">
        <div className="w-8 h-8 bg-gray-700 rounded-full"></div>
        <div className="flex-1 space-y-2">
          <div className="h-4 bg-gray-700 rounded w-3/4"></div>
          <div className="h-3 bg-gray-700 rounded w-1/2"></div>
        </div>
        <div className="w-8 h-8 bg-gray-700 rounded"></div>
      </div>
    </div>
    <div className="bg-gray-750 h-8"></div>
  </div>
);

const SkeletonLoader = () => {
  return (
    <div className="space-y-12">
      {/* Live Games Section */}
      <div>
        <div className="h-8 bg-gray-700 rounded w-1/4 mb-6"></div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
          <SkeletonGameCard />
          <SkeletonGameCard />
        </div>
      </div>

      {/* Upcoming Games Section */}
      <div>
        <div className="h-8 bg-gray-700 rounded w-1/4 mb-6"></div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
          <SkeletonGameCard />
          <SkeletonGameCard />
        </div>
      </div>

      {/* Completed Games Section */}
      <div>
        <div className="h-8 bg-gray-700 rounded w-1/4 mb-6"></div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <SkeletonGameCard />
          <SkeletonGameCard />
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
