import { getLatestAvailableWeek } from "./components/weekDates";
import { weeklyGames } from "./weeklyGamesData";


const USE_TEST_DATA = false; // Set this to false when you want to use live data

// Add mock test data
const testData = {
  6: [
    {
      home: {
        abbreviation: "ATL",
        name: "Atlanta Falcons",
        spread: -1.5,
      },
      away: {
        abbreviation: "TB",
        name: "Tampa Bay Buccaneers",
        spread: 1.5,
      },
      winner: "NULL",
      startTime: "2024-10-03T20:15:00-04:00",
    },
    {
      home: {
        abbreviation: "MIN",
        name: "Minnesota Vikings",
        spread: -2.5,
      },
      away: {
        abbreviation: "NYJ",
        name: "New York Jets",
        spread: 2.5,
      },
      winner: "NULL",
      startTime: "2024-10-06T09:30:00-04:00",
    },
    {
      home: {
        abbreviation: "NE",
        name: "New England Patriots",
        spread: -1,
      },
      away: {
        abbreviation: "MIA",
        name: "Miami Dolphins",
        spread: 1,
      },
      winner: "NULL",
      startTime: "2024-10-06T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "CHI",
        name: "Chicago Bears",
        spread: -3.5,
      },
      away: {
        abbreviation: "CAR",
        name: "Carolina Panthers",
        spread: 3.5,
      },
      winner: "NULL",
      startTime: "2024-10-06T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "JAX",
        name: "Jacksonville Jaguars",
        spread: -3,
      },
      away: {
        abbreviation: "IND",
        name: "Indianapolis Colts",
        spread: 3,
      },
      winner: "NULL",
      startTime: "2024-10-06T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "HOU",
        name: "Houston Texans",
        spread: 1,
      },
      away: {
        abbreviation: "BUF",
        name: "Buffalo Bills",
        spread: -1,
      },
      winner: "NULL",
      startTime: "2024-10-06T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "CIN",
        name: "Cincinnati Bengals",
        spread: 2.5,
      },
      away: {
        abbreviation: "BAL",
        name: "Baltimore Ravens",
        spread: -2.5,
      },
      winner: "NULL",
      startTime: "2024-10-06T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "WSH",
        name: "Washington Commanders",
        spread: -3,
      },
      away: {
        abbreviation: "CLE",
        name: "Cleveland Browns",
        spread: 3,
      },
      winner: "NULL",
      startTime: "2024-10-06T13:00:00-04:00",
    },
    {
      home: {
        abbreviation: "DEN",
        name: "Denver Broncos",
        spread: -3,
      },
      away: {
        abbreviation: "LV",
        name: "Las Vegas Raiders",
        spread: 3,
      },
      winner: "NULL",
      startTime: "2024-10-06T16:05:00-04:00",
    },
    {
      home: {
        abbreviation: "SF",
        name: "San Francisco 49ers",
        spread: -7.5,
      },
      away: {
        abbreviation: "ARI",
        name: "Arizona Cardinals",
        spread: 7.5,
      },
      winner: "NULL",
      startTime: "2024-10-06T16:05:00-04:00",
    },
    {
      home: {
        abbreviation: "SEA",
        name: "Seattle Seahawks",
        spread: -6,
      },
      away: {
        abbreviation: "NYG",
        name: "New York Giants",
        spread: 6,
      },
      winner: "NULL",
      startTime: "2024-10-06T16:25:00-04:00",
    },
  ],
};

export const currentWeek = getLatestAvailableWeek();

export async function updateScores(week) {
  if (USE_TEST_DATA) {
    // Use test data
    weeklyGames[week] = testData[week];
    return;
  }

  try {
    // Construct the URL with the week parameter
    const url = `https://site.api.espn.com/apis/site/v2/sports/football/nfl/scoreboard?week=${week}`;

    const response = await fetch(url); // Fetch the data for the specific week
    const data = await response.json();
    const games = data.events;

    games.forEach((game) => {
      const homeTeam = game.competitions[0].competitors.find(
        (c) => c.homeAway === "home"
      );
      const awayTeam = game.competitions[0].competitors.find(
        (c) => c.homeAway === "away"
      );

      const homeAbbr = homeTeam.team.abbreviation;
      const awayAbbr = awayTeam.team.abbreviation;
      const homeShortName = homeTeam.team.shortDisplayName;
      const awayShortName = awayTeam.team.shortDisplayName;
      const homeScore = homeTeam.score;
      const awayScore = awayTeam.score;
      const clock = game.status.displayClock;
      const period = game.status.period;
      const lastPlay = game.competitions[0].situation
        ? game.competitions[0].situation.lastPlay.text
        : "No last play info"; // Fetch the last play

      const downDistance = game.competitions[0].situation
        ? game.competitions[0].situation.downDistanceText
        : "No down and distance info"; // Fetch the down and distance information

      const possessionTeamId = game.competitions[0].situation
        ? game.competitions[0].situation.possession
        : null; // Get the possession team ID
      const possessionTeam = game.competitions[0].competitors.find(
        (team) => team.team.id === possessionTeamId
      );

      const possession = possessionTeam
        ? possessionTeam.team.abbreviation
        : "No possession info"; // Get the team abbreviation of the team in possession

      // Add this code to fetch and store the overall record
      const homeRecord =
        homeTeam.records.find((r) => r.type === "total" || r.type === "overall")
          ?.summary || "N/A";
      const awayRecord =
        awayTeam.records.find((r) => r.type === "total" || r.type === "overall")
          ?.summary || "N/A";

      // Find the corresponding game in weeklyGames and update the score and game status
      const weekGame = weeklyGames[week].find(
        (g) =>
          g.home.abbreviation === homeAbbr && g.away.abbreviation === awayAbbr
      );

      if (weekGame) {
        weekGame.homeScore = parseInt(homeScore);
        weekGame.awayScore = parseInt(awayScore);
        weekGame.clock = clock;
        weekGame.period = period;
        weekGame.lastPlay = lastPlay; // Add last play information
        weekGame.downDistance = downDistance; // Add down and distance information
        weekGame.possession = possession; // Add possession information
        weekGame.home.logo = homeTeam.team.logo; // Add home team logo
        weekGame.away.logo = awayTeam.team.logo; // Add away team logo
        weekGame.home.record = homeRecord;
        weekGame.away.record = awayRecord;
        weekGame.home.shortName = homeShortName;
        weekGame.away.shortName = awayShortName;

        // Determine winner based on spread
        if (game.status.type.completed) {
          let homeSpread = weekGame.home.spread;
          let awaySpread = weekGame.away.spread;

          const isEven = homeSpread === "E" || homeSpread === "Even";
          const scoreDifference = weekGame.homeScore - weekGame.awayScore;

          if (isEven) {
            if (scoreDifference > 0) {
              weekGame.winner = `${weekGame.home.abbreviation} (E)`;
            } else if (scoreDifference < 0) {
              weekGame.winner = `${weekGame.away.abbreviation} (E)`;
            } else {
              weekGame.winner = "PUSH";
            }
          } else {
            homeSpread = parseFloat(homeSpread);
            awaySpread = parseFloat(awaySpread);

            if (scoreDifference + homeSpread > 0) {
              const formattedSpread =
                homeSpread > 0 ? `+${homeSpread}` : homeSpread;
              weekGame.winner = `${weekGame.home.abbreviation} (${formattedSpread})`;
            } else if (scoreDifference + homeSpread < 0) {
              const formattedSpread =
                awaySpread > 0 ? `+${awaySpread}` : awaySpread;
              weekGame.winner = `${weekGame.away.abbreviation} (${formattedSpread})`;
            } else {
              weekGame.winner = "PUSH";
            }
          }
        }
      } else {
        console.log(`Game not found: ${homeAbbr} vs ${awayAbbr}`);
      }
    });
  } catch (error) {
    console.error("Error fetching scores:", error);
  }
}

// Modified function to get games for a week, now including scores
export const getGamesForWeek = (week) => {
  if (USE_TEST_DATA) {
    return testData[week] || [];
  }
  return weeklyGames[week] || [];
};

// Updated function to get winners for a specific week
export const getWinnersForWeek = (week) => {
  const games = weeklyGames[week] || [];
  return games.map((game) => ({
    winner: game.winner,
    home: game.home.abbreviation,
    away: game.away.abbreviation,
  }));
};

// New function to check if a pick is correct
export const isPickCorrect = (pick, gameResult) => {
  return (
    gameResult.winner !== "NULL" &&
    gameResult.winner !== "PUSH" &&
    gameResult.winner === pick
  );
};

// New function to check if a game has started
export const hasGameStarted = (gameStartTime) => {
  const now = new Date();
  return now >= new Date(gameStartTime);
};

export const hasGameFinished = (gameResult) => {
  const now = new Date();
  return now >= new Date(gameResult.startTime) && gameResult.winner !== "NULL";
};

// Modify this existing function
export const hasLastGameStarted = (week) => {
  const games = getGamesForWeek(week);
  if (games.length === 0) return false;

  const lastGame = games[games.length - 1];
  return hasGameStarted(lastGame.startTime);
};

// Add this new function
export const hasLastGameFinished = (week) => {
  const games = getGamesForWeek(week);
  if (games.length === 0) return false;

  const lastGame = games[games.length - 1];
  return hasGameFinished(lastGame);
};

export const getMondayNightScore = (week) => {
  const games = weeklyGames[week] || [];
  if (games.length === 0) return null;

  const mondayNightGame = games[games.length - 1]; // Last game of the week

  if (mondayNightGame && mondayNightGame.winner !== "NULL") {
    return mondayNightGame.homeScore + mondayNightGame.awayScore;
  }

  return null; // Return null if the game hasn't finished or doesn't exist
};