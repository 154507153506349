import React from "react";
import IosShareIcon from "@mui/icons-material/IosShare";
import { Dialog, DialogTitle, DialogBody, DialogActions } from "./dialog";
import { Button } from "./button"; // Assuming you have a Button component

const AddToHome = ({ isOpen, onClose }) => {
  // Add this useEffect to preload the image
  React.useEffect(() => {
    const img = new Image();
    img.src =
      "https://utfs.io/f/7P1pSdZBrdR0FgO9FWUNWSIUAYLoKjlQ5nqeu9ygEHwsMhar";
  }, []);

  return (
    <Dialog open={isOpen} onClose={onClose} size="sm">
      <DialogTitle className="text-lg font-bold text-gray-800 dark:text-white text-center pb-0">
        Install Our App
      </DialogTitle>
      <DialogBody className="px-3 py-2">
        <div className="flex flex-col items-center">
          {/* Smaller app icon */}
          <div className="bg-gradient-to-br from-blue-50 to-blue-100 dark:from-gray-700 dark:to-gray-800 p-2 rounded-xl shadow-md mb-3">
            <img
              src="https://utfs.io/f/7P1pSdZBrdR0FgO9FWUNWSIUAYLoKjlQ5nqeu9ygEHwsMhar"
              alt="App Icon"
              className="w-12 h-12 rounded-lg"
            />
          </div>

          {/* Shorter description */}
          <p className="text-center mb-3 text-gray-700 dark:text-gray-200 text-sm">
            Get instant access from your home screen
          </p>

          {/* Compact instruction steps */}
          <div className="space-y-4 w-full bg-gray-50 dark:bg-gray-800 rounded-lg p-3">
            <div className="flex flex-col items-center">
              <span className="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 text-xs font-semibold px-2.5 py-0.5 rounded-full mb-2">
                Step 1
              </span>
              <p className="text-gray-700 dark:text-gray-300 text-sm mb-1">
                Tap the share icon
              </p>
              <IosShareIcon
                className="text-blue-600 dark:text-blue-400"
                style={{ fontSize: "1.75em" }}
              />
            </div>

            {/* Add divider between steps */}
            <div className="border-t border-gray-200 dark:border-gray-700 w-1/2 mx-auto"></div>

            <div className="flex flex-col items-center">
              <span className="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 text-xs font-semibold px-2.5 py-0.5 rounded-full mb-2">
                Step 2
              </span>
              <p className="text-gray-700 dark:text-gray-300 text-sm">
                Select <span className="font-semibold">Add to Home Screen</span>
              </p>
            </div>
          </div>

          <p className="text-center mt-3 text-xs text-gray-500 dark:text-gray-400">
            Access native app-like features. No download required.
          </p>
        </div>
      </DialogBody>
      <DialogActions className="p-2 bg-gray-50 dark:bg-gray-800">
        <Button
          onClick={onClose}
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-1.5 px-4 rounded-lg text-sm transition-colors"
          color="blue"
        >
          Maybe Later
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddToHome;
