import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../supabaseClient";
import Modal from "./Modal";
import SignupModal from "./SignupModal";
import { Mail, Lock } from "lucide-react";

const preventZoom = `
  input, textarea, select {
    font-size: 16px;
  }
`;

const LoginComponent = ({ setUsername: setAppUsername }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: "", message: "" });
  const [isSignupVisible, setIsSignupVisible] = useState(true);

  useEffect(() => {
    const currentDate = new Date();
    // Set the cutoff date to September 5th, 2024 at 17:00 (5:00 PM)
    const cutoffDate = new Date("2024-09-09T20:20:00");

    if (currentDate >= cutoffDate) {
      setIsSignupVisible(false);
    }
  }, []);

  useEffect(() => {
    // Add the style to prevent zooming
    const style = document.createElement("style");
    style.textContent = preventZoom;
    document.head.appendChild(style);

    // Disable scrolling on mount
    document.body.style.overflow = "hidden";
    document.documentElement.style.overflow = "hidden";

    // Clean up function
    return () => {
      document.head.removeChild(style);
      document.body.style.overflow = "unset";
      document.documentElement.style.overflow = "unset";
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      console.log("Attempting to sign in with email:", email);
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) {
        console.error("Sign in error:", error);
        throw error;
      }

      if (data.user) {
        console.log(`Logged in as: ${data.user.email}`);

        // Fetch the username associated with this email
        const { data: profileData, error: profileError } = await supabase
          .from("profiles")
          .select("username")
          .eq("email", email)
          .single();

        if (profileError) {
          console.error("Error fetching username:", profileError);
          throw profileError;
        }

        if (profileData && profileData.username) {
          setAppUsername(profileData.username); // Set the username
          console.log(`Username set to: ${profileData.username}`);
        } else {
          console.error("No username found for this email");
          throw new Error("No username found for this email");
        }

        // Implement "Remember me" functionality
        if (rememberMe) {
          localStorage.setItem("rememberMe", "true");
          localStorage.setItem("userEmail", email);
        } else {
          localStorage.removeItem("rememberMe");
          localStorage.removeItem("userEmail");
        }

        navigate("/leaderboard"); // Redirect to LeaderboardPage instead of ScoresPage
      }

      localStorage.setItem("supabase.auth.token", JSON.stringify(data.session));
    } catch (error) {
      console.error("Login error:", error);
      setModalContent({
        title: "Login Error",
        message: error.message,
      });
      setIsLoginModalOpen(true);
    }
  };

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
  };

  const openSignupModal = () => {
    setIsSignupModalOpen(true);
  };

  const closeSignupModal = () => {
    setIsSignupModalOpen(false);
  };
  const handleSignup = (newUsername) => {
    console.log("New user signed up:", newUsername);
    setAppUsername(newUsername); // Update the app-level username
    closeSignupModal();
    navigate("/leaderboard"); // Redirect to leaderboard page after successful signup
  };

  const handleForgotPassword = () => {
    navigate("/password-reset");
  };

  // Add a new useEffect to check for remembered login
  useEffect(() => {
    const rememberedEmail = localStorage.getItem("userEmail");
    if (rememberedEmail) {
      setEmail(rememberedEmail);
      setRememberMe(true);
    }
  }, []);

  return (
    <main className="overflow-hidden bg-gradient-to-b from-gray-900 to-gray-800">
      <div className="isolate flex min-h-screen items-center justify-center p-6 lg:p-8">
        <div className="w-full max-w-md rounded-xl bg-gray-800 shadow-md ring-1 ring-white/5">
          <form onSubmit={handleSubmit} className="p-7 sm:p-11">
            <div className="flex items-start">
              <img
                src="https://utfs.io/f/7P1pSdZBrdR046DIGmuLynwNRD0JlaZu5KSTQ3rIkPdV8xjo"
                alt="Logo"
                className="h-9 rounded-xl"
              />
            </div>
            <h1 className="mt-8 text-base/6 font-medium text-white">
              Welcome back!
            </h1>
            <p className="mt-1 text-sm/5 text-gray-400">
              Sign in to your account to continue.
            </p>
            <div className="mt-8 space-y-3">
              <label
                htmlFor="email"
                className="text-sm/5 font-medium text-gray-300"
              >
                Email
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="block w-full pl-10 rounded-lg border border-gray-600 shadow-sm ring-1 ring-gray-700 bg-gray-700 text-white px-3 py-2 text-sm/6 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  placeholder="Enter your email"
                  autoCapitalize="none"
                  autoCorrect="off"
                  autoComplete="email"
                  spellCheck="false"
                />
              </div>
            </div>
            <div className="mt-8 space-y-3">
              <label
                htmlFor="password"
                className="text-sm/5 font-medium text-gray-300"
              >
                Password
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Lock className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="block w-full pl-10 rounded-lg border border-gray-600 shadow-sm ring-1 ring-gray-700 bg-gray-700 text-white px-3 py-2 text-sm/6 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  placeholder="Enter your password"
                  autoComplete="current-password"
                />
              </div>
            </div>
            <div className="mt-8 flex items-center justify-between text-sm/5">
              <div className="flex items-center gap-3">
                <input
                  type="checkbox"
                  id="remember-me"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  className="h-4 w-4 rounded border-gray-600 bg-gray-700 text-indigo-600 focus:ring-indigo-500"
                />
                <label htmlFor="remember-me" className="text-gray-300">
                  Remember me
                </label>
              </div>
              <button
                type="button"
                onClick={handleForgotPassword}
                className="font-medium text-indigo-400 hover:text-indigo-300"
              >
                Forgot password?
              </button>
            </div>
            <div className="mt-8">
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Sign in
              </button>
            </div>
          </form>
          {isSignupVisible && (
            <div className="m-1.5 rounded-lg text-white bg-gray-900 py-4 text-center text-sm/5 ring-1 ring-white/5">
              Want to join the pool?{" "}
              <button
                onClick={openSignupModal}
                className="font-medium text-indigo-300 hover:text-indigo-300"
              >
                Sign up
              </button>
            </div>
          )}
        </div>
      </div>

      <Modal
        isOpen={isLoginModalOpen}
        onClose={closeLoginModal}
        title={modalContent.title}
        type="error"
      >
        <p>{modalContent.message}</p>
      </Modal>

      <SignupModal
        isOpen={isSignupModalOpen}
        onClose={closeSignupModal}
        onSignup={handleSignup}
      />
    </main>
  );
};

export default LoginComponent;