import React, { useState, useEffect, useCallback } from "react";
import { supabase } from "../supabaseClient";
import { toast } from "react-toastify";
import { Eye, EyeOff, Camera, Trash2, User, Lock, Mail } from "lucide-react";
import AccountSettingsPageSkeleton from "./SkelAccount";

const AccountSettingsPage = ({
  userId,
  userName,
  setUserName,
  updateAvatarUrl,
}) => {
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [authEmail, setAuthEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [localAvatarUrl, setLocalAvatarUrl] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        // Fetch profile data
        const { data: profileData, error: profileError } = await supabase
          .from("profiles")
          .select("name, username, venmo, email")
          .eq("id", userId)
          .single();

        if (profileError) throw profileError;

        // Fetch avatar data
        const { data: avatarData, error: avatarError } = await supabase
          .from("user_avatars")
          .select("avatar_url")
          .eq("user_id", userId)
          .single();

        if (avatarError && avatarError.code !== "PGRST116") {
          throw avatarError;
        }

        // Fetch auth user data
        const { data: authData, error: authError } =
          await supabase.auth.getUser();

        if (authError) throw authError;

        setUserInfo(profileData);
        setAuthEmail(authData.user.email);
        setAvatarUrl(avatarData?.avatar_url || null);
      } catch (error) {
        console.error("Error fetching user data:", error);
        toast.error("Failed to load user information");
      } finally {
        setDataLoaded(true);
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userId]);

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setIsEditing(true);
    setLoading(true);

    if (newPassword !== confirmPassword) {
      toast.error("New passwords do not match");
      setLoading(false);
      setIsEditing(false);
      return;
    }

    try {
      // Verify the current password using the auth email
      const { data, error: signInError } =
        await supabase.auth.signInWithPassword({
          email: authEmail,
          password: currentPassword,
        });

      if (signInError || !data.user) {
        toast.error("Current password is incorrect");
        setLoading(false);
        setIsEditing(false);
        return;
      }

      // If current password is correct, update to the new password
      const { error: updateError } = await supabase.auth.updateUser({
        password: newPassword,
      });

      if (updateError) {
        toast.error("Error updating password: " + updateError.message);
      } else {
        toast.success("Password updated successfully");
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
      }
    } catch (error) {
      console.error("Error during password change:", error);
      toast.error("An error occurred while changing the password");
    } finally {
      setLoading(false);
      setIsEditing(false);
    }
  };

  const toggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const uploadProfilePicture = useCallback(
    async (event) => {
      try {
        setUploading(true);
        if (!event.target.files || event.target.files.length === 0) {
          setUploading(false);
          return;
        }

        const file = event.target.files[0];
        const localUrl = URL.createObjectURL(file);
        setLocalAvatarUrl(localUrl);

        const fileExt = file.name.split(".").pop();
        const fileName = `${Math.random()}.${fileExt}`;
        const filePath = `${userId}/${fileName}`;

        // Remove old profile picture if exists
        if (avatarUrl) {
          const oldFilePathMatch = avatarUrl.match(/profile_pictures\/(.+)$/);
          if (oldFilePathMatch && oldFilePathMatch[1]) {
            const oldFilePath = oldFilePathMatch[1];
            await supabase.storage
              .from("profile_pictures")
              .remove([oldFilePath]);
          }
        }

        const { error: uploadError } = await supabase.storage
          .from("profile_pictures")
          .upload(filePath, file);

        if (uploadError) {
          throw uploadError;
        }

        const { data: urlData } = supabase.storage
          .from("profile_pictures")
          .getPublicUrl(filePath);

        // Update or insert avatar URL and username in user_avatars table
        const { error: upsertError } = await supabase
          .from("user_avatars")
          .upsert(
            {
              user_id: userId,
              avatar_url: urlData.publicUrl,
              username: userInfo.username, // Add this line
            },
            { onConflict: "user_id" }
          );

        if (upsertError) {
          throw upsertError;
        }

        setAvatarUrl(urlData.publicUrl);
        updateAvatarUrl(urlData.publicUrl); // Pass the new URL to the parent component
        toast.success("Profile picture updated successfully");
      } catch (error) {
        console.error("Error uploading profile picture:", error);
        toast.error("Failed to upload profile picture: " + error.message);
        setLocalAvatarUrl(null);
      } finally {
        setUploading(false);
      }
    },
    [userId, avatarUrl, updateAvatarUrl, userInfo.username] // Add userInfo.username to the dependency array
  );

  const removeProfilePicture = useCallback(async () => {
    try {
      setUploading(true);

      if (avatarUrl) {
        const filePathMatch = avatarUrl.match(/profile_pictures\/(.+)$/);
        if (filePathMatch && filePathMatch[1]) {
          const filePath = filePathMatch[1];
          await supabase.storage.from("profile_pictures").remove([filePath]);
        }
      }

      // Remove avatar URL from user_avatars table
      const { error: deleteError } = await supabase
        .from("user_avatars")
        .delete()
        .eq("user_id", userId);

      if (deleteError) {
        throw deleteError;
      }

      setAvatarUrl(null);
      setLocalAvatarUrl(null);
      updateAvatarUrl(null); // Pass null to the parent component
      toast.success("Profile picture removed successfully");
    } catch (error) {
      console.error("Error removing profile picture:", error);
      toast.error("Failed to remove profile picture: " + error.message);
    } finally {
      setUploading(false);
    }
  }, [userId, avatarUrl, updateAvatarUrl]);

  // Replace the loading check with dataLoaded check
  if (!dataLoaded) {
    return <AccountSettingsPageSkeleton />;
  }

  return (
    <main className="overflow-hidden ">
      <div className="isolate flex min-h-screen items-center justify-center py-6 px-2 lg:p-8">
        <div className="w-full max-w-3xl rounded-xl bg-gray-800 shadow-md ring-1 ring-white/5">
          <div className="p-7 sm:p-11">
            <h1 className="text-2xl font-bold text-white mb-8">
              Account Settings
            </h1>

            <div className="space-y-8">
              {/* Profile Picture Section */}
              <div className="flex flex-col items-center">
                <div className="relative w-32 h-32 rounded-full overflow-hidden mb-4 ring-2 ring-indigo-500">
                  {localAvatarUrl || avatarUrl ? (
                    <img
                      src={localAvatarUrl || avatarUrl}
                      alt="Profile"
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <div className="w-full h-full bg-gray-700 flex items-center justify-center">
                      <User size={48} className="text-gray-400" />
                    </div>
                  )}
                  <label className="absolute inset-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center cursor-pointer group">
                    <Camera
                      size={24}
                      className="text-white opacity-0 group-hover:opacity-100 transition-opacity"
                    />
                    <input
                      type="file"
                      accept="image/*"
                      onChange={uploadProfilePicture}
                      disabled={uploading}
                      className="hidden"
                    />
                  </label>
                </div>
                {avatarUrl && (
                  <button
                    onClick={removeProfilePicture}
                    disabled={uploading}
                    className="mt-2 px-3 py-1.5 bg-red-600 text-sm text-white rounded-md hover:bg-red-700 transition-colors duration-200 flex items-center"
                  >
                    <Trash2 size={14} className="mr-1" />
                    <span>Remove Picture</span>
                  </button>
                )}
              </div>

              {/* User Info Section */}
              {loading ? (
                <p className="text-gray-300">Loading user information...</p>
              ) : (
                <div className="space-y-4">
                  {Object.entries(userInfo).map(([key, value]) => {
                    if (key !== "avatar_url") {
                      return (
                        <div key={key} className="space-y-1">
                          <label className="text-sm font-medium text-gray-300">
                            {key.charAt(0).toUpperCase() + key.slice(1)}
                          </label>
                          <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                              {key === "email" ? (
                                <Mail
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              ) : (
                                <User
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              )}
                            </div>
                            <div className="block w-full pl-10 rounded-lg border border-gray-600 shadow-sm ring-1 ring-gray-700 bg-gray-700 text-white px-3 py-2 text-base sm:text-sm/6 cursor-default">
                              {value || ""}
                            </div>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}

              {/* Separator */}
              <hr className="border-t border-gray-700" />

              {/* Change Password Section */}
              <div className="space-y-4">
                <h2 className="text-xl font-bold text-white flex items-center">
                  <Lock className="mr-2" size={20} />
                  Change Password
                </h2>
                <form onSubmit={handlePasswordChange} className="space-y-4">
                  <div className="space-y-1">
                    <label
                      className="text-sm font-medium text-gray-300"
                      htmlFor="currentPassword"
                    >
                      Current Password
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <Lock
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        type={showCurrentPassword ? "text" : "password"}
                        id="currentPassword"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        className="block w-full pl-10 rounded-lg border border-gray-600 shadow-sm ring-1 ring-gray-700 bg-gray-700 text-white px-3 py-2 text-base sm:text-sm/6 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      />
                      <button
                        type="button"
                        onClick={toggleCurrentPasswordVisibility}
                        className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-white"
                      >
                        {showCurrentPassword ? (
                          <EyeOff size={20} />
                        ) : (
                          <Eye size={20} />
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      className="text-sm font-medium text-gray-300"
                      htmlFor="newPassword"
                    >
                      New Password
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <Lock
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        type={showNewPassword ? "text" : "password"}
                        id="newPassword"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className="block w-full pl-10 rounded-lg border border-gray-600 shadow-sm ring-1 ring-gray-700 bg-gray-700 text-white px-3 py-2 text-base sm:text-sm/6 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      />
                      <button
                        type="button"
                        onClick={toggleNewPasswordVisibility}
                        className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-white"
                      >
                        {showNewPassword ? (
                          <EyeOff size={20} />
                        ) : (
                          <Eye size={20} />
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      className="text-sm font-medium text-gray-300"
                      htmlFor="confirmPassword"
                    >
                      Confirm New Password
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <Lock
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        type={showNewPassword ? "text" : "password"}
                        id="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="block w-full pl-10 rounded-lg border border-gray-600 shadow-sm ring-1 ring-gray-700 bg-gray-700 text-white px-3 py-2 text-base sm:text-sm/6 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    disabled={loading || isEditing}
                    className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                      (loading || isEditing) && "opacity-50 cursor-not-allowed"
                    }`}
                  >
                    {loading
                      ? "Updating..."
                      : isEditing
                      ? "Processing..."
                      : "Change Password"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AccountSettingsPage;
